import * as THREE from 'three';
import * as SavaneJS from '@rhinov/savane-js';
import async from 'async-es';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { WebglScene } from "../webgl/scene";
import { ReplaceCoatingCommand } from './commands/ReplaceCoatingCommand';
import { ReplaceKitchenCoatingCommand } from './commands/ReplaceKitchenCoatingCommand';
import { ChangeKitchenStyleCommand } from './commands/ChangeKitchenStyleCommand';
import { WebglEntity } from '../webgl/entity';
import { WebglHullEntity } from '../webgl/hullEntity';
import { ReplaceArrangementCommand } from './commands/ReplaceArrangementCommand';

declare var AssetManagerServices;

export class InteractiveProjectHandler {

    private divWebgl: HTMLElement;
    private scene: WebglScene;
    private hoverOutlinePass: OutlinePass;
    private interactiveProjectGizmo: THREE.Group;
    interactiveProjectGizmoX: THREE.Group<THREE.Object3DEventMap>;
    interactiveProjectGizmoZ: THREE.Group<THREE.Object3DEventMap>;
    interactiveProjectGizmoY: THREE.Group<THREE.Object3DEventMap>;
    selectedNode: any[];
    onTopArrangementCollected: boolean;

    constructor(divWebgl, scene) {
        this.divWebgl = divWebgl;
        this.scene = scene;
        this.hoverOutlinePass = new OutlinePass(new THREE.Vector2(this.divWebgl.clientWidth, this.divWebgl.clientHeight), this.scene.threeScene, this.scene.defaultCamera);
        this.hoverOutlinePass.visibleEdgeColor = new THREE.Color("#8EC5B7").convertSRGBToLinear();
        this.scene.composer.addPass(this.hoverOutlinePass);

        this.interactiveProjectGizmo = new THREE.Group();
        this.interactiveProjectGizmo.renderOrder = -1000;

        const arrowGeometry = new THREE.CylinderGeometry( 0, 0.05, 0.2, 12, 1, false );
        const gizmoMaterial = new THREE.MeshBasicMaterial( {
            depthTest: false,
            depthWrite: false,
            transparent: true,
            opacity: 0.3,
            side: THREE.DoubleSide,
            fog: false,
            toneMapped: false
        } );
        const lineGeometry = new THREE.BufferGeometry();
        lineGeometry.setAttribute( 'position', new THREE.Float32BufferAttribute( [ -1, 0, 0,	1, 0, 0 ], 3 ) );

        let leftMaterial = gizmoMaterial.clone(); leftMaterial.color.set(0xff0000);
        let leftMesh = new THREE.Mesh(arrowGeometry, leftMaterial);
        leftMesh.position.x = -1;
        leftMesh.rotation.z = Math.PI / 2;
        let rightMesh = new THREE.Mesh(arrowGeometry, leftMaterial);
        rightMesh.rotation.z = -Math.PI / 2;
        rightMesh.position.x = 1;
        let leftRightLine = new THREE.Line(lineGeometry, leftMaterial);
        this.interactiveProjectGizmoX = new THREE.Group();
        this.interactiveProjectGizmoX.add(leftMesh);
        this.interactiveProjectGizmoX.add(rightMesh);
        this.interactiveProjectGizmoX.add(leftRightLine);

        let upMaterial = gizmoMaterial.clone(); upMaterial.color.set(0x0000ff);
        let upMesh = new THREE.Mesh(arrowGeometry, upMaterial);
        upMesh.rotation.x = Math.PI / 2;
        upMesh.position.z = 1;
        let downMesh = new THREE.Mesh(arrowGeometry, upMaterial);
        downMesh.rotation.x = -Math.PI / 2;
        downMesh.position.z = -1;
        let upDownLine = new THREE.Line(lineGeometry, upMaterial);
        upDownLine.rotation.y = Math.PI / 2;
        this.interactiveProjectGizmoZ = new THREE.Group();
        this.interactiveProjectGizmoZ.add(upMesh);
        this.interactiveProjectGizmoZ.add(downMesh);
        this.interactiveProjectGizmoZ.add(upDownLine);

        let forwardMaterial = gizmoMaterial.clone(); forwardMaterial.color.set(0x0000ff);
        let forwardMesh = new THREE.Mesh(arrowGeometry, forwardMaterial);
        forwardMesh.position.y = 1;
        let backwardMesh = new THREE.Mesh(arrowGeometry, forwardMaterial);
        backwardMesh.rotation.z = -Math.PI;
        backwardMesh.position.y = -1;
        let forwardBackwardLine = new THREE.Line(lineGeometry, forwardMaterial);
        forwardBackwardLine.rotation.z = Math.PI / 2;
        this.interactiveProjectGizmoY = new THREE.Group();
        this.interactiveProjectGizmoY.add(forwardMesh);
        this.interactiveProjectGizmoY.add(backwardMesh);
        this.interactiveProjectGizmoY.add(forwardBackwardLine);

        this.interactiveProjectGizmo.add(this.interactiveProjectGizmoX);
        this.interactiveProjectGizmo.add(this.interactiveProjectGizmoZ);
        this.interactiveProjectGizmo.add(this.interactiveProjectGizmoY);

        this.selectedNode = [];
        this.onTopArrangementCollected = false;

        Savane.eventsManager.instance.addListener(SavaneJS.Events.SELECTED_ARRANGEMENT_REPLACED, function(event) {
            this.ReplaceArrangement(event.userData.toReplace, event.userData.replaceBy);
            this.scene.render();
        }.bind(this));

        Savane.eventsManager.instance.addListener(SavaneJS.Events.SELECTED_COATING_REPLACED, function(event) {
            this.ReplaceCoating(event.userData.entity, event.userData.toReplace, event.userData.replaceBy, event.userData.isCredence);
            this.scene.render();
        }.bind(this));

        Savane.eventsManager.instance.addListener(SavaneJS.Events.SELECTED_KITCHEN_COATING_REPLACED, function(event) {
            this.ReplaceKitchenCoating(event.userData.entity, event.userData.replaceBy, event.userData.topBottoms, event.userData.topBottomsConstrained);
            this.scene.render();
        }.bind(this));

        Savane.eventsManager.instance.addListener(SavaneJS.Events.SELECTED_KITCHEN_HANDLE_REPLACED, function(event) {
            this.ReplaceKitchenHandle(event.userData.entities, event.userData.handle);
            this.scene.render();
        }.bind(this));

        Savane.eventsManager.instance.addListener(SavaneJS.Events.SELECTED_PLINTH_COATING_REPLACED, function(event) {
            let entities = event.userData.entities;
            let coating = event.userData.coating;
            for (let i = 0; i < entities.length; ++i) {
                let entity = entities[i];
                let coatings = entity.getComponents(SavaneJS.ComponentConstants.ComponentType.Coating);
                for (let j = 0; j < coatings.length; j++) {
                    if (coatings[j].hangType === SavaneJS.Coating.HangType.kitchenplinth) {
                        // Remove previous coating
                        entity.removeComponent(coatings[j]);
                        break;
                    }
                }
                entity.addComponent(coating);
            }
            Savane.eventsManager.instance.dispatch(SavaneJS.Events.RHINOV_FORMAT_UPDATED, { scene: entities[0].scene });
            Savane.eventsManager.instance.dispatch(SavaneJS.Events.PROJECT_REFRESH_HULL);
        }.bind(this));

        Savane.eventsManager.instance.addListener(SavaneJS.Events.CHANGE_KITCHEN_STYLE, function(event) {
            let command = new ChangeKitchenStyleCommand(this, event.userData.entities, event.userData.style, event.userData.coatingId);
            command.execute(false);
            this.scene.render();
        }.bind(this));

        Savane.eventsManager.instance.addListener(SavaneJS.Events.MOVE_ARRANGEMENT_LEFT, () => {
            this._moveSelectedObject(new THREE.Vector3(-1, 0, 0));
        });

        Savane.eventsManager.instance.addListener(SavaneJS.Events.MOVE_ARRANGEMENT_RIGHT, () => {
            this._moveSelectedObject(new THREE.Vector3(1, 0, 0));
        });

        Savane.eventsManager.instance.addListener(SavaneJS.Events.MOVE_ARRANGEMENT_FORWARD, () => {
            this._moveSelectedObject(new THREE.Vector3(0, 1, 0));
        });

        Savane.eventsManager.instance.addListener(SavaneJS.Events.MOVE_ARRANGEMENT_BACKWARD, () => {
            this._moveSelectedObject(new THREE.Vector3(0, -1, 0));
        });

        Savane.eventsManager.instance.addListener(SavaneJS.Events.MOVE_ARRANGEMENT_UP, () => {
            this._moveSelectedObject(new THREE.Vector3(0, 0, 1));
        });

        Savane.eventsManager.instance.addListener(SavaneJS.Events.MOVE_ARRANGEMENT_DOWN, () => {
            this._moveSelectedObject(new THREE.Vector3(0, 0, -1));
        });

        Savane.eventsManager.instance.addListener(SavaneJS.Events.ROTATE_ARRANGEMENT_LEFT, () => {
            this._rotateSelectedObject(1);
        });

        Savane.eventsManager.instance.addListener(SavaneJS.Events.ROTATE_ARRANGEMENT_RIGHT, () => {
            this._rotateSelectedObject(-1);
        });

        Savane.eventsManager.instance.addListener(SavaneJS.Events.HIDE_ARRANGEMENT, () => {
            this._hideSelectedObject();
        });

        Savane.eventsManager.instance.addListener(SavaneJS.Events.RHINOV_FORMAT_RESETED, () => {
            this.onTopArrangementCollected = false;
        });

        Savane.eventsManager.instance.addListener(SavaneJS.Events.START_EDIT_MODE, () => {
            if (!this.onTopArrangementCollected) {
                let arrangementObjects = this.scene.savaneScene.arrangementObjects;
                for (let i = 0; i < arrangementObjects.length; ++i) {
                    arrangementObjects[i].onTopEntities = this.collectArrangementsOnTop(arrangementObjects[i]);
                }
                this.scene.hideExcludedObject(this.scene.camera);
                this.onTopArrangementCollected = true;
            }

            if (this.selectedNode.length > 0) {
                this._addMoveArrow(this.selectedNode[0].object);
            }
        });

        Savane.eventsManager.instance.addListener(SavaneJS.Events.EXIT_EDIT_MODE, () => {
            this._removeMoveArrow();
            if (this.scene.outlinePass) {
                let previousSelectedObjects = this.scene.outlinePass.selectedObjects.slice();
                this.scene.outlinePass.selectedObjects = [];
                previousSelectedObjects.forEach(function(object) {
                    object.traverse(function(item) {
                        this.scene.setLayer(item, 0);
                    }.bind(this));
                }.bind(this));
            }
            this.selectedNode = [];
        });
    }

    HandleKeyboard(event) {
        let camera = this.scene.camera;
        if (!camera) {
            return;
        }

        event.stopPropagation();
        event.preventDefault();
        switch (event.keyCode) {
            case 37: //left
                Savane.eventsManager.instance.dispatch(SavaneJS.Events.MOVE_ARRANGEMENT_LEFT);
                break;
            case 39: //right
                Savane.eventsManager.instance.dispatch(SavaneJS.Events.MOVE_ARRANGEMENT_RIGHT);
                break;
            case 38: //up
                Savane.eventsManager.instance.dispatch(SavaneJS.Events.MOVE_ARRANGEMENT_FORWARD);
                break;
            case 40: //down
                Savane.eventsManager.instance.dispatch(SavaneJS.Events.MOVE_ARRANGEMENT_BACKWARD);
                break;
            /*case 90: //z
                Savane.eventsManager.instance.dispatch(Savane.Events.MOVE_ARRANGEMENT_UP);
                break;
            case 83: //s
                Savane.eventsManager.instance.dispatch(Savane.Events.MOVE_ARRANGEMENT_DOWN);
                break;
            case 65: //a
                Savane.eventsManager.instance.dispatch(Savane.Events.ROTATE_ARRANGEMENT_LEFT);
                break;
            case 81: //q
                Savane.eventsManager.instance.dispatch(Savane.Events.ROTATE_ARRANGEMENT_RIGHT);
                break;
            case 72: //h
                Savane.eventsManager.instance.dispatch(Savane.Events.HIDE_ARRANGEMENT);
                break;
            case 69: //e
                Savane.eventsManager.instance.dispatch(Savane.Events.START_EDIT_MODE);
            break;
            case 82: //r
                Savane.eventsManager.instance.dispatch(Savane.Events.EXIT_EDIT_MODE);
            break;*/
        }
        this.scene.render();
    }

    _moveSelectedObject(direction) {
        let factor = 0.3;
        let forward = new THREE.Vector3();
        let up = new THREE.Vector3();
        let right = new THREE.Vector3();

        for (let i = 0; i < this.selectedNode.length; ++i) {
            let node = this.selectedNode[i];
            if (!(node.entity.isArrangementObjectEntity() || node.entity.isArrangementGroupEntity())) continue;
            let rotation = new THREE.Matrix4().extractRotation(this.interactiveProjectGizmo.matrixWorld);
            rotation.extractBasis(right, forward, up);

            let move = new THREE.Vector3();
            if (this.interactiveProjectGizmoY.visible === false) {
                move.add(right.multiplyScalar(direction.x));
                move.add(up.multiplyScalar(direction.y));
            } else if (this.interactiveProjectGizmoZ.visible === false) {
                move.add(right.multiplyScalar(direction.x));
                move.add(forward.multiplyScalar(direction.y));
            } else {
                move.add(right.multiplyScalar(direction.x));
                move.add(forward.multiplyScalar(direction.y));
                move.add(up.multiplyScalar(direction.z));
            }
            move.normalize();

            let mNode = move.clone();
            if (node.entity.parent.isArrangementGroupEntity()) {
                mNode.applyMatrix4(new THREE.Matrix4().extractRotation(node.object.parent.matrixWorld).invert());
            }
            mNode.multiplyScalar(factor);
            node.object.position.add(mNode);
            node.object.updateMatrix();
            node.applyToEntity('translate');

            if (node.entity.onTopEntities) {
                for (let j = 0; j < node.entity.onTopEntities.length; ++j) {
                    let onTopItem = this.scene.getPlanEntity(node.entity.onTopEntities[j].id);
                    if (!onTopItem) continue;
                    let iNode = move.clone();
                    if (onTopItem.entity.parent.isArrangementGroupEntity()) {
                        iNode = iNode.applyMatrix4(new THREE.Matrix4().extractRotation(onTopItem.object.parent.matrixWorld).invert());
                    }
                    iNode.multiplyScalar(factor);
                    onTopItem.object.position.add(iNode);
                    onTopItem.object.updateMatrix();
                    (onTopItem as WebglEntity | WebglHullEntity).applyToEntity('translate', 'world');
                }
            }

            let bbox = new THREE.Box3().setFromObject(node.object);
            let center = new THREE.Vector3();
            bbox.getCenter(center);
            this._computeInteractiveProjectGizmoRotation(node);
            this.interactiveProjectGizmo.position.copy(center);
            this.interactiveProjectGizmo.updateWorldMatrix(false, true);

            this.scene.render();
        }

        Savane.eventsManager.instance.dispatch(SavaneJS.Events.RHINOV_FORMAT_UPDATED, { scene: this.scene.savaneScene });
    }

    _rotateSelectedObject(direction) {
        for (let i = 0; i < this.selectedNode.length; ++i) {
            let node = this.selectedNode[i];
            let scale = node.object.scale.clone();
            node.object.scale.set(1, 1, 1);
            node.object.updateWorldMatrix(true, true);

            let onTopEntitiesParents = [];
            if (node.entity.onTopEntities) {
                for (let j = 0; j < node.entity.onTopEntities.length; ++j) {
                    let onTopItem = this.scene.getPlanEntity(node.entity.onTopEntities[j].id);
                    onTopEntitiesParents.push(onTopItem.object.parent);
                    node.object.attach(onTopItem.object);
                }
            }

            let angleStep = direction * 0.0174533 * 5;
            node.object.rotateOnWorldAxis(new THREE.Vector3(0, 0, 1), angleStep);
            node.object.updateMatrix();

            if (node.entity.onTopEntities) {
                for (let j = 0; j < node.entity.onTopEntities.length; ++j) {
                    let onTopItem = this.scene.getPlanEntity(node.entity.onTopEntities[j].id);
                    let parent = onTopEntitiesParents[j];
                    parent.attach(onTopItem.object);
                    (onTopItem as WebglEntity | WebglHullEntity).applyToEntity('rotate', 'world');
                }
            }

            node.object.scale.set(scale.x, scale.y, scale.z);
            node.object.updateWorldMatrix(true, true);
            node.applyToEntity('rotate', 'world');

            this._computeInteractiveProjectGizmoRotation(node);
            this.scene.render();
        }

        Savane.eventsManager.instance.dispatch(SavaneJS.Events.RHINOV_FORMAT_UPDATED, { scene: this.scene.savaneScene });
    }

    isGizmo(object) {
        let parent = object.parent;
        while (parent) {
            if (parent === this.interactiveProjectGizmo) {
                return true;
            }
            parent = parent.parent;
        }
    }

    removeGizmoFromIntersectionResults(intersects) {
        intersects = this.scene.removeGizmoFromIntersectionResults(intersects, true);
        return intersects.filter(function(item) {
            if  (item.object === this.interactiveProjectGizmo) {
                return false;
            }

            if (this.isGizmo(item.object)) {
                return false;
            }

            return true;
        }.bind(this));
    }

    getBox3CenterAndCorners(box) {
        let result = [];
        let center = new THREE.Vector3();
        box.getCenter(center);
        center.z = box.max.z;
        result.push(center);

        result.push(box.min);
        let corner = new THREE.Vector3().copy(box.min);
        corner.x = box.max.x;
        result.push(corner);
        corner = new THREE.Vector3().copy(box.min);
        corner.x = box.max.x;
        corner.y = box.max.y;
        result.push(corner);
        corner = new THREE.Vector3().copy(box.min);
        corner.y = box.max.y;
        result.push(corner);

        return result;
    }

    collectArrangementsOnTop(arrangement) {
        let result = [];
        let glEntity = this.scene.getPlanEntity(arrangement.id);
        if (!glEntity) {
            return result;
        }

        let glEntityBox = new THREE.Box3().setFromObject(glEntity.object);
        let glEntityBoxExtended = glEntityBox.clone();
        let glEntityCenter = new THREE.Vector3();
        glEntityBox.getCenter(glEntityCenter);
        let glEntitySize = new THREE.Vector3();
        glEntityBox.getSize(glEntitySize);
        let newSizeZ = (glEntity.entity.maxHeight / 100) + 2;
        glEntityBoxExtended.max.z = newSizeZ;
        for (let i = 0; i < this.scene.furnitures.length; ++i) {
            let furniture = this.scene.furnitures[i];
            
            // init volatile isUnder array
            if (!furniture.entity.isUnder) {
                furniture.entity.isUnder = [];
            }

            if (!furniture.entity.stackable) {
                continue;
            }

            if (furniture.entity.isArrangementObjectEntity() == false) {
                continue;
            }

            if (furniture.entity.id === arrangement.id) {
                continue;
            }

            if (furniture.entity.anchor[2] !== -1) {
                continue;
            }

            let glFurnitureBox = new THREE.Box3().setFromObject(furniture.object);
            let center = new THREE.Vector3();
            glFurnitureBox.getCenter(center);

            let points = this.getBox3CenterAndCorners(glFurnitureBox);
            let intesect = glFurnitureBox.clone()
            intesect.intersect(glEntityBoxExtended);
            if (intesect.isEmpty() === false && isNaN(center.x) === false) {
                let idDown = undefined;
                let found = false, isUnder = false;

                // Check if the object is under
                if (glEntityBox.containsPoint(center)) {
                    isUnder = true;
                }

                for (let k = 0; k < points.length; ++k) {
                    let point = points[k];
                    // check if furniture is under the the arrangement
                    {
                        let direction = new THREE.Vector3(0, 0, -1);
                        this.scene.rayCaster.set(point, direction);

                        this.scene.setLayer(furniture.object, 1);
                        let intersects = this.scene.rayCaster.intersectObjects(this.scene.threeScene.children, true);
                        intersects = this.removeGizmoFromIntersectionResults(intersects);
                        this.scene.setLayer(furniture.object, 0);
                        for (let j = 0; j < intersects.length; ++j) {
                            idDown = intersects[j].object.parent.userData.id;
                            if (idDown === undefined) {
                                idDown = intersects[j].object.userData.id;
                            }
                            if (idDown === arrangement.id) {
                                found = true;
                                break;
                            }
                        }
                        if (found) break;
                    }
                }

                if (found || isUnder) {
                    result.push(furniture.entity);
                }
                if (isUnder) {
                    furniture.entity.isUnder.push(arrangement.id);
                }
            }
        }
        return result;
    }

    _computeInteractiveProjectGizmoRotation(node) {
        let object = node.object;
        let camera = this.scene.getActiveGLCamera() as THREE.PerspectiveCamera;
        let forward = new THREE.Vector3();
        let up = new THREE.Vector3();
        let right = new THREE.Vector3();

        camera.matrixWorld.extractBasis(right, up, forward);

        let rotation = new THREE.Matrix4().extractRotation(object.matrixWorld);
        let X = new THREE.Vector3();
        let Y = new THREE.Vector3();
        let Z = new THREE.Vector3();
        rotation.extractBasis(X, Y, Z);

        let Axis = 'X';
        let dotMax = Number.MIN_VALUE;

        let gUp = new THREE.Vector3(0, 0, 1);
        let gRight = new THREE.Vector3();
        let gForward = new THREE.Vector3();

        // find right axis
        if (node.entity.anchor[1] !== -1) {
            let xDot = Math.abs(right.dot(X));
            let yDot = Math.abs(right.dot(Y));
            let zDot = Math.abs(right.dot(Z));
            if (xDot > dotMax) {
                Axis = 'X';
                dotMax = xDot;
            }
            if (yDot > dotMax) {
                Axis = 'Y';
                dotMax = yDot;
            }
            if (zDot > dotMax) {
                Axis = 'Z';
                dotMax = zDot;
            }

            if (Axis === 'X') {
                gRight.copy(X);
                if (right.dot(X) < 0) {
                    gRight.negate();
                }
            } else if (Axis === 'Y') {
                gRight.copy(Y);
                if (right.dot(Y) < 0) {
                    gRight.negate();
                }
            } else if (Axis === 'Z') {
                gRight.copy(Z);
                if (right.dot(Z) < 0) {
                    gRight.negate();
                }
            }
        } else {
            gRight.copy(X);
            if (right.dot(X) < 0) {
                gRight.negate();
            }
        }

        gForward.crossVectors(gUp, gRight).normalize();
        gRight.crossVectors(gForward, gUp).normalize();

        let matrix = new THREE.Matrix4();

        matrix.makeBasis(gRight, gForward, gUp);
        this.interactiveProjectGizmo.quaternion.copy(new THREE.Quaternion().setFromRotationMatrix(matrix));
    }

    _addMoveArrow(object) {
        let id = this.scene.getIdFromSelectedObject(object);
        if (!id) {
            return;
        }

        let node = this.scene.getPlanEntity(id);
        if (!node) {
            return;
        }

        this.interactiveProjectGizmoY.visible = true;
        this.interactiveProjectGizmoZ.visible = true;
        if (node.entity.anchor[1] < 0) {
            this.interactiveProjectGizmoY.visible = false;
        } else {
            this.interactiveProjectGizmoZ.visible = false;
        }

        let camera = this.scene.getActiveGLCamera() as THREE.PerspectiveCamera;
        let bbox = new THREE.Box3().setFromObject(object);
        let center = new THREE.Vector3();
        bbox.getCenter(center);

        let factor = center.distanceTo( camera.position ) * Math.min( 1.9 * Math.tan( Math.PI * camera.fov / 360 ) / camera.zoom, 7 );
        this.interactiveProjectGizmo.scale.set( 1, 1, 1 ).multiplyScalar( factor / 7 );

        this.scene.threeScene.add(this.interactiveProjectGizmo);
        this._computeInteractiveProjectGizmoRotation(node);
        this.interactiveProjectGizmo.position.copy(center);
        this.interactiveProjectGizmo.updateWorldMatrix(false, true);
    }

    _removeMoveArrow() {
        this.scene.threeScene.remove(this.interactiveProjectGizmo);
    }

    _hideObject(node) {
        if (node.entity.hidden) {
            node.object.traverse(function(child) {
                if (child.isMesh) {
                    child.onBeforeRender = function(renderer, scene, camera, geometry, material, group) {
                        if (material.wasTransparent === undefined && material.transparent) {
                            material.wasTransparent = material.transparent;
                            material.initialOpacity = material.opacity;
                        } else {
                            material.wasTransparent = false;
                        }

                        material.transparent = true;
                        material.opacity = 0.3;
                    }

                    if (Array.isArray(child.material)) {
                        for (let i = 0; i < child.material.length; ++i) {
                            child.material[i].needsUpdate = true;
                        }
                    } else {
                        child.material.needsUpdate = true;
                    }
                }
            });

        } else {
            node.object.traverse(function(child) {
                if (child.isMesh) {
                    child.onBeforeRender = function(renderer, scene, camera, geometry, material, group) {
                        if (material.wasTransparent) {
                            material.opacity = material.initialOpacity;
                        } else {
                            material.transparent = false;
                            material.opacity = 1;
                        }
                    }
                }
            });
        }
    }

    _hideSelectedObject() {
        for (let i = 0; i < this.selectedNode.length; ++i) {
            let node = this.selectedNode[i];
            node.entity.hidden = !node.entity.hidden;
            this._hideObject(node);
        }
        Savane.eventsManager.instance.dispatch(SavaneJS.Events.RHINOV_FORMAT_UPDATED, { scene: this.scene.savaneScene });
        this.scene.render();
    }

    SelectArrangement(point) {
        let mousePosition = new THREE.Vector2(point.x, point.y);
        let camera = this.scene.getActiveGLCamera() as THREE.PerspectiveCamera;
        this.scene.rayCaster.setFromCamera(mousePosition, camera);
        let intersects = this.scene.rayCaster.intersectObjects(this.scene.threeScene.children, true);
        intersects = this.removeGizmoFromIntersectionResults(intersects);

        if (intersects.length === 0) {
            this.scene.render();
            return;
        }

        // Try to get the savane entity id stored into the userdata of the object
        let id = intersects[0].object.parent.userData.id;

        // This is for objects that do not have VLP they'll only have the default mesh instead of the vlp itself, do not search for parent then
        if (id === undefined) {
            id = intersects[0].object.userData.id;
        }

        if (id !== undefined) {
            let node = this.scene.getPlanEntity(id);

            //DEBUG collectArrangmentOnTop
            //node.entity.onTopEntities = this.collectArrangementsOnTop(node.entity);

            let isKitchenEntity = this._isKitchenArrangement(node.entity);
            if (!node.entity.customization || isKitchenEntity) {
                this.selectedNode = [node];
                if (this.selectedNode.indexOf(node) !== -1) {
                    Savane.eventsManager.instance.dispatch(SavaneJS.Events.SELECTED_ARRANGEMENT, { entity: node.entity, point: point, isKitchenEntity: isKitchenEntity });
                    if (this.scene.outlinePass) {
                        if (node.entity.isArrangementObjectEntity()) {
                            this.scene.outlinePass.hiddenEdgeColor = new THREE.Color("#000000").convertSRGBToLinear();
                        } else {
                            this.scene.outlinePass.hiddenEdgeColor = new THREE.Color("#F5D231").convertSRGBToLinear();
                        }
                    }
                    if (this.scene.outlinePass) {
                        this.scene.outlinePass.selectedObjects.push(node.object);
                    }
                }
                Savane.eventsManager.instance.dispatch(SavaneJS.Events.START_EDIT_MODE);
            }
        } else {
            Savane.eventsManager.instance.dispatch(SavaneJS.Events.EXIT_EDIT_MODE);
        }

        this.scene.render();
    }

    UpdateOnTopEntities(toReplace, replaceBy) {
        let arrangements = this.scene.savaneScene.arrangementObjects;
        for (let i = 0; i < arrangements.length; ++i) {
            let item = arrangements[i];
            if (item.onTopEntities) {
                for (let j = 0; j < item.onTopEntities.length; ++j) {
                    if (item.onTopEntities[j].id === toReplace.id) {
                        item.onTopEntities[j] = replaceBy;
                        break;
                    }
                }
            }
        }
    }

    UpdateShoppingList(oldId, newId) {
        let cameras = this.scene.savaneScene.renderCameras;
        for (let i = 0; i < cameras.length; ++i) {
            let camera = cameras[i];
            for (let j = 0; j < camera.shoppingList.length; ++j) {
                if (camera.shoppingList[j] === oldId) {
                    camera.shoppingList[j] = newId;
                    break;
                }
            }
        }
    }

    ReplaceArrangement(toReplace, replaceBy) {
        this.UpdateShoppingList(toReplace.objectId, replaceBy.objectId);
        let command = new ReplaceArrangementCommand(this, toReplace, replaceBy, false);
        command.execute(false, null);
    }

    SelectCoating(point) {
        let mousePosition = new THREE.Vector2(point.x, point.y);
        let camera = this.scene.getActiveGLCamera() as THREE.PerspectiveCamera;
        this.scene.rayCaster.setFromCamera(mousePosition, camera);
        let intersects = this.scene.rayCaster.intersectObjects(this.scene.threeScene.children, true);
        intersects = this.removeGizmoFromIntersectionResults(intersects);

        let previousSelectedObjects;
        if (this.scene.outlinePass) {
            previousSelectedObjects = this.scene.outlinePass.selectedObjects.slice();
            this.scene.outlinePass.selectedObjects = [];
            previousSelectedObjects.forEach(function(object) {
                object.traverse(function(item) {
                    this.scene.setLayer(item, 0);
                }.bind(this));
            }.bind(this));
        }

        if (intersects.length === 0) {
            this.scene.render();
            return;
        }

        let intersect = intersects[0];
        // hull entity
        let entity = null;
        let childs = [];
        if (!entity && this.scene.floorGeneratorHull) {
            entity = this.scene.floorGeneratorHull.getEntityFromChild(intersect.object);
            if (entity) {
                childs = this.scene.floorGeneratorHull.getChildsFromId(entity.id);
            }
        }
        if (!entity && this.scene.dynamicHull) {
            entity = this.scene.dynamicHull.getEntityFromChild(intersect.object);
            if (entity) {
                childs = this.scene.dynamicHull.getChildsFromId(entity.id);
            }
        }
        if (!entity && this.scene.staticHull) {
            entity = this.scene.staticHull.getEntityFromChild(intersect.object);
            if (entity) {
                childs = this.scene.staticHull.getChildsFromId(entity.id);
            }
        }

        let result = false;
        if (entity && (entity.isWallEntity() || entity.isRoomEntity() || entity.isJoineryEntity() ||
                entity.isGeometryPrimitiveEntity() || entity.isFloorEntity() || this._isTechnicalElement(entity) || this._isWorktopEntity(entity))) {
            let node = this.scene.getPlanEntity(entity.id);

            let coatingArea = intersect.object.name.startsWith("CoatingArea");
            let credence = intersect.object.name.startsWith("Credence");

            if (this._isWorktopEntity(entity)) {
                childs = childs.filter(function(item) {
                    if (credence) {
                        return item.name.startsWith("Credence");
                    } else {
                        return item.name.startsWith("Worktop");
                    }
                });
            }

            if (this.scene.outlinePass) {
                if (previousSelectedObjects.indexOf(intersect.object) !== -1) {
                    return false;
                }
            } else if (this.selectedNode.indexOf(node) !== -1) {
                return false;
            }
            this.selectedNode = [node];

            if (this.scene.outlinePass) {
                this.scene.outlinePass.selectedObjects = childs;
            }
            // coating
            let hangType = intersect.object.name.indexOf("Direct") !== -1 ? SavaneJS.Coating.HangType.wallDirect : SavaneJS.Coating.HangType.wallUndirect;
            if (entity.isWallEntity()) {
                if (intersect.object.name.indexOf("Slope") !== -1) {
                    if (intersect.object.name.indexOf("Direct") === -1) {
                        hangType = SavaneJS.Coating.HangType.slopeUndirect;
                    }
                    else {
                        hangType = SavaneJS.Coating.HangType.slopeDirect;
                    }
                }
            } else if (entity.isRoomEntity() || entity.isFloorEntity()) {
                hangType = intersect.object.name.indexOf("axo") !== -1 ? SavaneJS.Coating.HangType.ceiling : SavaneJS.Coating.HangType.floor;
            } else if (entity.isJoineryEntity()) {
                hangType = SavaneJS.Coating.HangType.joinery;
            } else if (entity.isTechnicalElementEntity() || entity.isGeometryPrimitiveEntity()) {
                hangType = SavaneJS.Coating.HangType.technicalElement;
            } else if (entity.isWorktopEntity()) {
                hangType = SavaneJS.Coating.HangType.floor;
            }
            if (coatingArea) {
                let coatingAreaParse = intersect.object.name.split('_');
                let coatingAreaNb = Number(coatingAreaParse[2]) - 1;
                let coatings = entity.getComponents(SavaneJS.ComponentConstants.ComponentType.CoatingArea);
                Savane.eventsManager.instance.dispatch(SavaneJS.Events.SELECTED_COATING, { entity: entity, coating: coatings[coatingAreaNb], point: point });
                result = true;
            }
            else if ((intersect.object as any).coatingId) {
                //Check coating
                let coatings = [];
                if (coatingArea == false && credence == false) {
                    coatings = coatings.concat(entity.getComponents(SavaneJS.ComponentConstants.ComponentType.Coating));
                } else if (credence) {
                    coatings = coatings.concat(entity.getComponents(SavaneJS.ComponentConstants.ComponentType.Credence));
                }
                if (coatings.length == 0) {
                    coatings = entity.getComponents(SavaneJS.ComponentConstants.ComponentType.FloorCoatingArea);
                }
                if ((coatings !== null) && (coatings.length > 0)) {
                    for (let i = 0; i < coatings.length; i++) {
                        if ((intersect.object as any).coatingId !== coatings[i].coatingId) {
                            continue;
                        }
                        if (coatings[i].hangType !== hangType) {
                            continue;
                        }
                        Savane.eventsManager.instance.dispatch(SavaneJS.Events.SELECTED_COATING, { entity: entity, coating: coatings[i], isCredence: credence, point: point });
                        result = true;
                        break;
                    }
                }
            } else if (entity.isJoineryEntity() || entity.isTechnicalElementEntity() || entity.isGeometryPrimitiveEntity()) {
                let coatings = entity.getComponents(SavaneJS.ComponentConstants.ComponentType.Coating);
                if ((coatings !== null) && (coatings.length > 0)) {
                    for (let i = 0; i < coatings.length; i++) {
                        if (coatings[i].hangType !== hangType && coatings[i].hangType !== SavaneJS.Coating.HangType.usemtl) {
                            continue;
                        }
                        Savane.eventsManager.instance.dispatch(SavaneJS.Events.SELECTED_COATING, { entity: entity, coating: coatings[i], point: point });
                        result = true;
                        break;
                    }
                } else {
                    Savane.eventsManager.instance.dispatch(SavaneJS.Events.SELECTED_COATING, { entity: entity, coating: { hangType: hangType, entity: entity }, point: point });
                    result = true;
                }
            } else {
                Savane.eventsManager.instance.dispatch(SavaneJS.Events.SELECTED_COATING, { entity: entity, coating: { hangType: hangType, entity: entity }, point: point });
                result = true;
            }
        }
        this.scene.render();
        return result;
    }

    ReplaceCoating(entity, toReplace, replaceBy, isCredence) {
        this.UpdateShoppingList(toReplace.coatingId, replaceBy.coatingId);
        let command = new ReplaceCoatingCommand(this, entity, toReplace, replaceBy, isCredence);
        command.execute(false);
    }

    ReplaceKitchenCoating(entity, replaceBy, topBottoms, topBottomsConstrained) {
        let command = new ReplaceKitchenCoatingCommand(this, entity, replaceBy, topBottoms, topBottomsConstrained)
        command.execute(false);
    }

    ReplaceKitchenHandle(entities, handle) {
        async.eachSeries(entities, function(entity, callback) {
            if (handle) {
                AssetManagerServices.createAssetEntity(AssetManagerServices._ASSET_TYPE.ARRANGEMENTS, handle, true, function(handleEntity) {
                    this.scene.removeEntity(entity);
                    entity.populateWithHandles(handleEntity, entity.handleSide);
                    this.scene.addEntityTree(entity, function() {
                        callback();
                    });
                }.bind(this));
            } else {
                this.scene.removeEntity(entity);
                entity.handleAsset = '';
                entity.clearAllHandles(entity.handleSide);
                this.scene.addEntityTree(entity, function() {
                    callback();
                });
            }
        }.bind(this), function() {
            this.scene.render();
            Savane.eventsManager.instance.dispatch(SavaneJS.Events.RHINOV_FORMAT_UPDATED, { scene: entities[0].scene, command: null });
        }.bind(this));
    }

    HandleMouseDown(event) {
        if (event.button === 0) {
            let coatingSelected = this.SelectCoating({
                x: (event.offsetX / event.target.clientWidth) * 2 - 1,
                y: ((event.target.clientHeight - event.offsetY) / event.target.clientHeight) * 2 - 1,
            });
            if (!coatingSelected) {
                this.SelectArrangement({
                    x: (event.offsetX / event.target.clientWidth) * 2 - 1,
                    y: ((event.target.clientHeight - event.offsetY) / event.target.clientHeight) * 2 - 1,
                });
            } else {
                this._removeMoveArrow();
            }
        }
    }

    Hovering(event) {
        this._hovering({
            x: (event.offsetX / event.target.clientWidth) * 2 - 1,
            y: ((event.target.clientHeight - event.offsetY) / event.target.clientHeight) * 2 - 1,
        });
    }

    Resize() {
        if (this.hoverOutlinePass) {
            this.hoverOutlinePass.setSize(this.divWebgl.clientWidth, this.divWebgl.clientHeight);
        }
    }

    UpdateCamera() {
        let camera = this.scene.getActiveGLCamera() as THREE.PerspectiveCamera;
        if (this.hoverOutlinePass) {
            this.hoverOutlinePass.renderCamera = camera;
        }
        this.selectedNode = [];
        if (this.scene.outlinePass) {
            this.scene.outlinePass.selectedObjects = [];
        }
        this._removeMoveArrow();
        this.scene.hideExcludedObject(this.scene.camera);
    }

    _isKitchenArrangement(entity) {
        if (entity.objectType === SavaneJS.SceneConstants.ArrangementType.kitchenFurnitureBottom ||
            entity.objectType === SavaneJS.SceneConstants.ArrangementType.kitchenFurnitureTop ||
            entity.objectType === SavaneJS.SceneConstants.ArrangementType.kitchenFurnitureColumn) {
            return true;
        }
        else {
            return false;
        }
    };

    _isWorktopEntity(entity) {
        if (entity.entityType === SavaneJS.SceneConstants.EntityType.WorkTop) {
            return true;
        }

        return false;
    }

    _isTechnicalElement(entity) {
        if (entity.isTechnicalElementEntity() == false) return false;
        if (entity.objectId === SavaneJS.SceneConstants.TechnicalElementType.pole ||
            entity.objectId === SavaneJS.SceneConstants.TechnicalElementType.radiator ||
            entity.objectId === SavaneJS.SceneConstants.TechnicalElementType.rosette ||
            entity.objectId === SavaneJS.SceneConstants.TechnicalElementType.beam ||
            entity.objectId === SavaneJS.SceneConstants.TechnicalElementType.frame ||
            entity.objectId === SavaneJS.SceneConstants.TechnicalElementType.wallDecoration) {
            return true;
        }

        return false;
    }

    _hovering(point) {
        let mousePosition = new THREE.Vector2(point.x, point.y);
        let camera = this.scene.getActiveGLCamera() as THREE.PerspectiveCamera;
        this.scene.rayCaster.setFromCamera(mousePosition, camera);
        let intersects = this.scene.rayCaster.intersectObjects(this.scene.threeScene.children, true);
        intersects = this.removeGizmoFromIntersectionResults(intersects);

        if (this.hoverOutlinePass) {
            this.hoverOutlinePass.hiddenEdgeColor = new THREE.Color("#000000").convertSRGBToLinear();
        }

        this._clearHovering();

        if (intersects.length === 0) {
            this.scene.render();
            return;
        }

        let intersect = intersects[0];

        let id = intersect.object.parent.userData.id;

        // This is for objects that do not have VLP they'll only have the default mesh instead of the vlp itself, do not search for parent then
        if (id === undefined) {
            id = intersect.object.userData.id;
        }

        if (id !== undefined) {
            // Try to get the 3D node from the 3D entity list
            let node = this.scene.getPlanEntity(id);

            if (!node.entity.customization || this._isKitchenArrangement(node.entity)) {
                if (this.hoverOutlinePass) {
                    this.hoverOutlinePass.selectedObjects.push(node.object);
                }
            }
        }

        if (id === undefined) {
            // hull entity
            let childs = [];
            let entity = null;
            if (!entity && this.scene.floorGeneratorHull) {
                entity = this.scene.floorGeneratorHull.getEntityFromChild(intersect.object);
                if (entity) {
                    childs = this.scene.floorGeneratorHull.getChildsFromId(entity.id);
                    if (this.hoverOutlinePass) {
                        this.hoverOutlinePass.hiddenEdgeColor = new THREE.Color("#8EC5B7").convertSRGBToLinear();
                    }
                }
            }
            if (!entity && this.scene.dynamicHull) {
                entity = this.scene.dynamicHull.getEntityFromChild(intersect.object);
                if (entity) {
                    childs = this.scene.dynamicHull.getChildsFromId(entity.id);
                    if (this.hoverOutlinePass) {
                        this.hoverOutlinePass.hiddenEdgeColor = new THREE.Color("#8EC5B7").convertSRGBToLinear();
                    }
                }
            }
            if (!entity && this.scene.staticHull) {
                entity = this.scene.staticHull.getEntityFromChild(intersect.object);
                if (entity) {
                    childs = this.scene.staticHull.getChildsFromId(entity.id);
                    if (this.hoverOutlinePass) {
                        this.hoverOutlinePass.hiddenEdgeColor = new THREE.Color("#8EC5B7").convertSRGBToLinear();
                    }
                }
            }

            if (entity && (entity.isWallEntity() || entity.isRoomEntity() || entity.isJoineryEntity() ||
                    entity.isFloorEntity() || this._isTechnicalElement(entity) || this._isWorktopEntity(entity))) {
                if (this.hoverOutlinePass) {
                    this.hoverOutlinePass.selectedObjects = childs;
                }
            }
        }

        this.scene.render();
    }

    _clearHovering() {
        if (this.hoverOutlinePass) {
            this.hoverOutlinePass.selectedObjects = [];
        }
    }

}
