import { Floor, ArrangementObject, Transform, arrangementManager } from '../../SavaneJS';
import { TemporaryEntity } from './TemporaryEntity';
    
/**
 * TemporaryIArrangement is used for arrangementObject edition
 */
export class TemporaryArrangementObject extends TemporaryEntity {
    public isAnchorActive: boolean;
    public width: number;
    public height: number;
    public length: number;
    public objectId: string;
    public manufacturer: any;
    public retailer: any;
    public lightOn: boolean;
    public lightOff: boolean;
    public temperature: number;
    public lightColor: any;
    public symmetry: boolean;
    public coatingAllowed: boolean;
    public hidden: boolean;

    private _transform: Transform;

    constructor(arrangement: ArrangementObject) {
        super(arrangement);

        // Save anchor active only, all the rest is in the transform structure (positions and orientations)
        this.isAnchorActive = arrangement.isAnchorActive;

        this._transform = new Transform(null);
        this.width = arrangement.width;
        this.height = arrangement.height;
        this.length = arrangement.length;
        this.objectId = arrangement.objectId;
        this.manufacturer = arrangement.manufacturer;
        this.retailer = arrangement.retailer;
        if (arrangement.lightOn !== undefined) {
            this.lightOn = arrangement.lightOn;
        }
        if (arrangement.lightOff !== undefined) {
            this.lightOff = arrangement.lightOff;
        }
        if (arrangement.temperature !== undefined) {
            this.temperature = arrangement.temperature;
        }
        if (arrangement.lightColor !== undefined) {
            this.lightColor = arrangement.lightColor;
        }
        this.symmetry = arrangement.symmetry;
        this.coatingAllowed = arrangement.coatingAllowed;
        this.hidden = arrangement.hidden;
        this._transform.clone(arrangement.transform);
    }

    computeValidity(floor: Floor) {
        this._isValid = arrangementManager.isArrangementValid(this.entity as ArrangementObject);
    }

    get transform(): Transform {
        return this._transform;
    }
}
