import { Partition } from '../model/Partition';
import { glMatrix, Ray, Line } from '../../../math/Math';
import { Photo2World } from '../Photo2World';

export class PartitionFactory {

    private _pixels: Array<glMatrix.vec2> = new Array<glMatrix.vec2>();
    private _values: Array<number> = new Array<number>();
    private _values2: Array<number> = new Array<number>();
    private _zoom: number = 1;
    private _photo2world: Photo2World;

    constructor(photo2world: Photo2World) {
        this._photo2world = photo2world;
    }

    set Pixels(value: Array<glMatrix.vec2>) {
        this._pixels = value;
    }

    set Values(value: Array<number>) {
        this._values = value;
    }

    set Values2(value: Array<number>) {
        this._values2 = value;
    }

    set Zoom(value: number) {
        this._zoom = value;
    }

    Build() : void {
        this._photo2world.Model.Partitions = [];

        if (this._pixels.length < 2) {
            return;
        }

        var index = 0;
        for (var i = 0; i < this._pixels.length - 1; i += 2) {
            //Build partitions from given pixels
            var A = this._photo2world.ProjectOnPartitionGround(this._pixels[i]);
            if (A === null) {
                return;
            }

            var B = this._photo2world.ProjectOnPartitionGround(this._pixels[i + 1]);
            if (B === null) {
                return;
            }

            if (this._pixels[i + 1][0] < this._pixels[i][0]) {
                var C = A;
                A = B;
                B = C;
            }

            this._photo2world.Model.Partitions.push(new Partition(A, B, this._values2[index], this._values[index], false));
            index++;
        }

        //find wall to snap partition to
        if (this._photo2world.Magnet) {
            var magnetDistance = 10 / this._zoom;
            for (var i = 0; i < this._photo2world.Model.Partitions.length; ++i) {
                var partition = this._photo2world.Model.Partitions[i]

                //find closest walls
                var wallA = null, wallB = null;
                var offsetA = glMatrix.vec3.create();
                glMatrix.vec3.add(offsetA, partition.A, partition.NegateD); //Offset to prevent self intersection
                wallA = this._photo2world.FindClosestWallOrPartitionWithRay(new Ray(offsetA, partition.NegateD), true);
                var offsetB = glMatrix.vec3.create();
                glMatrix.vec3.add(offsetB, partition.B, partition.D); //Offset to prevent self intersection
                wallB = this._photo2world.FindClosestWallOrPartitionWithRay(new Ray(offsetB, partition.D), true);
                
                //if length < magnetDistance then snap to wall
                if (wallA) {
                    var line = new Line(this._photo2world.Project(wallA.wall.A), this._photo2world.Project(wallA.wall.B));
                    var pI = this._photo2world.Project(wallA.intersection);
                    var pA = this._photo2world.Project(partition.A);
                    if (line.BelongToSegment(pI) && glMatrix.vec3.distance(pI, pA) < magnetDistance) {
                        partition.WallA = wallA.wall;
                    } else {
                        partition.WallA = null;
                    }
                }

                if (wallB) {
                    var line = new Line(this._photo2world.Project(wallB.wall.A), this._photo2world.Project(wallB.wall.B));
                    var pI = this._photo2world.Project(wallB.intersection);
                    var pB = this._photo2world.Project(partition.B);
                    if (line.BelongToSegment(pB) && glMatrix.vec3.distance(pI, pB) < magnetDistance) {
                        partition.WallB = wallB.wall;
                    } else {
                        partition.WallB = null;
                    }
                }
            }
        }
    }

    Magnetize(P: glMatrix.vec3, Fuline: Line, Fvline: Line) : glMatrix.vec3 {
        var magnetDistance = 10 / this._zoom;

        //Corner magnetize
        for (var i = 0; i < this._photo2world.Model.Partitions.length; ++i) {
            var partition = this._photo2world.Model.Partitions[i];

            if (partition.WallA) {
                var pA = this._photo2world.Project(partition.WallA.A);
                if (glMatrix.vec3.distance(pA, P) < magnetDistance) {
                    return pA;
                }
                var pB = this._photo2world.Project(partition.WallA.B);
                if (glMatrix.vec3.distance(pB, P) < magnetDistance) {
                    return pB;
                }
            }
            if (partition.WallB) {
                var pA = this._photo2world.Project(partition.WallB.A);
                if (glMatrix.vec3.distance(pA, P) < magnetDistance) {
                    return pA;
                }
                var pB = this._photo2world.Project(partition.WallB.B);
                if (glMatrix.vec3.distance(pB, P) < magnetDistance) {
                    return pB;
                }
            }
        }

        let P3 = glMatrix.vec3.fromValues(P[0], P[1], 0);
        //Wall magnetize
        for (var i = 0; i < this._photo2world.Model.Partitions.length; ++i) {
            var partition = this._photo2world.Model.Partitions[i];

            if (partition.WallA) {
                var line = new Line(this._photo2world.Project(partition.WallA.A), this._photo2world.Project(partition.WallA.B));
                if (glMatrix.vec3.distance(line.Intersect(Fuline), P3) < magnetDistance) {
                    return line.Intersect(Fuline);
                }
                if (glMatrix.vec3.distance(line.Intersect(Fvline), P3) < magnetDistance) {
                    return line.Intersect(Fvline);
                }
                
                if (line.Distance(glMatrix.vec3.fromValues(P[0], P[1], 0)) < magnetDistance) {
                    var pP = line.Project(P3);
                    if (line.BelongToSegment(pP)) {
                        return pP;
                    }
                }
            }
            if (partition.WallB) {
                var line = new Line(this._photo2world.Project(partition.WallB.A), this._photo2world.Project(partition.WallB.B));
                if (glMatrix.vec3.distance(line.Intersect(Fuline), P3) < magnetDistance) {
                    return line.Intersect(Fuline);
                }
                if (glMatrix.vec3.distance(line.Intersect(Fvline), P3) < magnetDistance) {
                    return line.Intersect(Fvline);
                }
                
                if (line.Distance(P3) < magnetDistance) {
                    var pP = line.Project(P3);
                    if (line.BelongToSegment(pP)) {
                        return pP;
                    }
                }
            }
        }

        return P;
    }

}
