import { Component, Coating, Functionality, ComponentConstants, Scene, Floor, Room, Wall, Joinery, GeometryPrimitive, RenderCamera, ArrangementObject, ArrangementGroup, WorkTop, SceneConstants, roomManager } from "../SavaneJS";
import { TechnicalElement } from "./TechElements/TechnicalElement";
import { Staircase } from "./Staircases/Staircase";
import { Entity } from "./Entity";

export class World extends Entity {
    public _activeDrawTime: number = 0;
    public _idleDrawTime: number = 0;
    public _idleDrawDurations: any = [];

    public _activeDrawCRTime: number = 0;
    public _idleDrawCRTime: number = 0;
    public _idleDrawCRDurations: any = [];

    public _activeDrawCRVisioTime: number = 0;
    public _idleDrawCRVisioTime: number = 0;
    public _idleDrawCRVisioDurations: any = [];

    public _activeConstructionTime: number = 0;
    public _idleConstructionTime: number = 0;
    public _idleConstructionDurations: any = [];

    public _activeConstructionCRTime: number = 0;
    public _idleConstructionCRTime: number = 0;
    public _idleConstructionCRDurations: any = [];

    public _activeConstructionCRVisioTime: number = 0;
    public _idleConstructionCRVisioTime: number = 0;
    public _idleConstructionCRVisioDurations: any = [];

    public _activeDecorationTime: number = 0;
    public _idleDecorationTime: number = 0;
    public _idleDecorationDurations: any = [];

    public _activeDecorationCRTime: number = 0;
    public _idleDecorationCRTime: number = 0;
    public _idleDecorationCRDurations: any = [];

    public _activeDecorationCRVisioTime: number = 0;
    public _idleDecorationCRVisioTime: number = 0;
    public _idleDecorationCRVisioDurations: any = [];

    public _logActiveTime: number = 0;
    public _logIdleTime: number = 0;

    public _nbAMRequests: number = 0;
    public _totalTimeAMRequests: number = 0;
    public _minTimeAMRequests: number = 60 * 1000;
    public _maxTimeAMRequests: number = 0;
    public _nbStaticHullRequests: number = 0;
    public _totalTimeStaticHullRequests: number = 0;
    public _nbDynamicHullRequests: number = 0;
    public _totalTimeDynamicHullRequests: number = 0;
    public _nbFloorHullRequests: number = 0;
    public _totalTimeFloorHullRequests: number = 0;

    private lastTimerCall: number;

    public currentScene: Scene;

    constructor(id) {
        super();

        this._id = id;

        this.lastTimerCall = new Date().getTime();
    }

    get arrangementObjects(): Array<ArrangementObject> {
        let result = [];
        for (let i = 0; i < this.children.length; ++i) {
            result = result.concat((this.children[i] as Scene).arrangementObjects);
        }
        return result;
    }

    get arrangementGroups(): Array<ArrangementGroup> {
        let result = [];
        for (let i = 0; i < this.children.length; ++i) {
            result = result.concat((this.children[i] as Scene).arrangementGroups);
        }
        return result;
    }

    get worktops(): Array<WorkTop> {
        let result = [];
        for (let i = 0; i < this.children.length; ++i) {
            result = result.concat((this.children[i] as Scene).worktops);
        }
        return result;
    }

    get kitchenArrangementObjects(): Array<ArrangementObject> {
        let result = [];
        for (let i = 0; i < this.children.length; ++i) {
            result = result.concat((this.children[i] as Scene).kitchenArrangementObjects);
        }
        return result;
    }

    get bathroomArrangementObjects(): Array<ArrangementObject> {
        let result = [];
        for (let i = 0; i < this.children.length; ++i) {
            result = result.concat((this.children[i] as Scene).bathroomArrangementObjects);
        }
        return result;
    }

    get walls(): Array<Wall> {
        let result = [];
        for (let i = 0; i < this.children.length; ++i) {
            result = result.concat((this.children[i] as Scene).walls);
        }
        return result;
    }

    get geometryPrimitives(): Array<GeometryPrimitive> {
        let result = [];
        for (let i = 0; i < this.children.length; ++i) {
            result = result.concat((this.children[i] as Scene).geometryPrimitives);
        }
        return result;
    }

    get technicalElements(): Array<TechnicalElement> {
        let result = [];
        for (let i = 0; i < this.children.length; ++i) {
            result = result.concat((this.children[i] as Scene).technicalElements);
        }
        return result;
    }

    get stairCases(): Array<Staircase> {
        let result = [];
        for (let i = 0; i < this.children.length; ++i) {
            result = result.concat((this.children[i] as Scene).stairCases);
        }
        return result;
    }

    get joineries(): Array<Joinery> {
        let result = [];
        for (let i = 0; i < this.children.length; ++i) {
            result = result.concat((this.children[i] as Scene).joineries);
        }
        return result;
    }

    get renderCameras(): Array<RenderCamera> {
        let result = [];
        for (let i = 0; i < this.children.length; ++i) {
            result = result.concat((this.children[i] as Scene).renderCameras);
        }
        return result;
    }

    get coatings(): Array<Component> {
        let result = [];
        for (let i = 0; i < this.children.length; ++i) {
            result = result.concat((this.children[i] as Scene).coatings);
        }
        return result;
    }

    get floors(): Array<Floor> {
        let result = [];
        for (let i = 0; i < this.children.length; ++i) {
            result = result.concat((this.children[i] as Scene).floors);
        }
        return result;
    }

    get rooms(): Array<Room> {
        let result = [];
        for (let i = 0; i < this.children.length; ++i) {
            result = result.concat((this.children[i] as Scene).rooms);
        }
        return result;
    }

    getShoppingListPerRoom(): any {
        let shoppingList = {
            rooms: [],
            coatings: [],
        };

        let allCoatings = this.coatings;

        for (let i = 0; i < allCoatings.length; i++) {
            if ((allCoatings[i] as Coating).manufacturer && (allCoatings[i] as Coating).manufacturer._id !== "5be2c40c2e50ee46876e546d") {
                if (shoppingList.coatings.indexOf((allCoatings[i] as Coating).coatingId) === -1) {
                    shoppingList.coatings.push((allCoatings[i] as Coating).coatingId);
                }
            }
        }

        let allObjects = this.arrangementObjects;

        for (let i = 0; i < allObjects.length; i++) {
            if (allObjects[i].manufacturer && allObjects[i].manufacturer._id !== "5be2c40c2e50ee46876e546d" && allObjects[i].manufacturer._id !== "5df89bb3c5b06666d1a38703") {
                let room = allObjects[i].room;
                let roomId;

                if (room) {
                    roomId = room.id;
                } else {
                    let floor = allObjects[i].floor;

                    if (floor) {
                        room = roomManager.getRoomAtPosition(allObjects[i].position, floor);
                        if (room) {
                            roomId = room.id;
                        } else {
                            roomId = -1;
                        }
                    } else {
                        roomId = -1;
                    }
                }

                let roomIdx = shoppingList.rooms.map((e) => e.id).indexOf(roomId);
                if (roomIdx === -1) {
                    // Add room to list
                    let roomName = "Divers";

                    if (roomId !== -1) {
                        let component = room.getComponent(ComponentConstants.ComponentType.Functionality);

                        if (component && (component as Functionality).functionalityName) {
                            roomName = (component as Functionality).functionalityName;
                        } else {
                            roomName = room.name;
                        }
                    }
                    let newRoom = {
                        id: roomId,
                        name: roomName,
                        objects: [
                            {
                                id: allObjects[i].objectId,
                                name: allObjects[i].name,
                                count: 1,
                            },
                        ],
                    };
                    shoppingList.rooms.push(newRoom);
                } else {
                    let objectIdx = shoppingList.rooms[roomIdx].objects.map((e) => e.id).indexOf(allObjects[i].objectId);
                    if (objectIdx === -1) {
                        let newObject = {
                            id: allObjects[i].objectId,
                            name: allObjects[i].name,
                            count: 1,
                        };
                        shoppingList.rooms[roomIdx].objects.push(newObject);
                    } else {
                        shoppingList.rooms[roomIdx].objects[objectIdx].count++;
                    }
                }
            }
        }

        let miscRoomIdx = shoppingList.rooms.map((e) => e.id).indexOf(-1);
        let miscRoom = null;
        if (miscRoomIdx !== -1) {
            miscRoom = shoppingList.rooms[miscRoomIdx];
            shoppingList.rooms.splice(miscRoomIdx, 1);
        }

        shoppingList.rooms.sort((a, b) => {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        });

        if (miscRoom) {
            shoppingList.rooms.push(miscRoom);
        }

        return shoppingList;
    }

    getCustomizableObjectsByTypeAndManufacturer(objectTypeArray: Array<string>, objectManufacturerArray: Array<string>): Array<ArrangementObject> {
        let result = [];
        for (let i = 0; i < this.children.length; ++i) {
            result = result.concat((this.children[i] as Scene).getCustomizableObjectsByTypeAndManufacturer(objectTypeArray, objectManufacturerArray));
        }
        return result;
    }

    getSchneiderSwitchCoatings(): any {
        let result = { buttons: [], flatpieces: [] };
        for (let i = 0; i < this.children.length; ++i) {
            let data = (this.children[i] as Scene).getSchneiderSwitchCoatings();
            result.buttons = result.buttons.concat(data.buttons);
            result.flatpieces = result.flatpieces.concat(data.flatpieces);
        }
        return result;
    }

    getIxinaKitchenStyle(): any | null {
        for (let i = 0; i < this.children.length; ++i) {
            let data = (this.children[i] as Scene).getIxinaKitchenStyle();
            if (data) {
                return data;
            }
        }
        return null;
    }

    getIxinaKitchenTriplets(): Array<{objectId: string, doorCoatingId: string, groupId: string}> {
        let result = [];
        for (let i = 0; i < this.children.length; ++i) {
            result = result.concat((this.children[i] as Scene).getIxinaKitchenTriplets());
        }
        return result;
    }

    getIxinaKitchenIds(): {
        doorIds: Array<string>,
        worktopIds: Array<string>,
        credenceIds: Array<string>,
        specialObjectsIds: Array<string>,
        electroIds: Array<string>
    } {
        let result = {
            doorIds: [],
            worktopIds: [],
            credenceIds: [],
            specialObjectsIds: [],
            electroIds: [],
        };
        for (let i = 0; i < this.children.length; ++i) {
            let data = (this.children[i] as Scene).getIxinaKitchenIds();
            result.doorIds = result.doorIds.concat(data.doorIds);
            result.worktopIds = result.worktopIds.concat(data.worktopIds);
            result.credenceIds = result.credenceIds.concat(data.credenceIds);
            result.specialObjectsIds = result.specialObjectsIds.concat(data.specialObjectsIds);
            result.electroIds = result.electroIds.concat(data.electroIds);
        }
        return result;
    }

    getAllAssetManagerIds(): {
        arrangementsIds: Array<string>,
        coatingIds: Array<string>,
        joineryIds: Array<string>,
        technicalElementIds: Array<string>
    } {
        let arrangements = this.arrangementObjects;
        //let worktops = this.worktops;
        let coatings = this.coatings;
        let joineries = this.joineries;
        let techElements = this.technicalElements;
        let result = {
            arrangementsIds: [],
            coatingIds: [],
            joineryIds: [],
            technicalElementIds: [],
        };
        let i, j, comp;
        for (i = 0; i < coatings.length; i++) {
            if (result.coatingIds.indexOf((coatings[i] as Coating).coatingId) === -1) {
                result.coatingIds.push((coatings[i] as Coating).coatingId);
            }
        }
        /*for (i = 0 ; i < worktops.length ; i++) {
            comp = worktops[i].getComponents(Savane.ComponentConstants.ComponentType.Coating);
            if (comp) {
                for (j = 0 ; j < comp.length ; j++) {
                    if (result.coatingIds.indexOf(comp[j]._coatingId) === -1) {
                        result.coatingIds.push(comp[j]._coatingId);
                    }
                }
            }
            comp = worktops[i].getComponents(Savane.ComponentConstants.ComponentType.Credence);
            if (comp) {
                for (j = 0 ; j < comp.length ; j++) {
                    if (result.coatingIds.indexOf(comp[j]._coatingId) === -1) {
                        result.coatingIds.push(comp[j]._coatingId);
                    }
                }
            }
        }*/
        for (i = 0; i < arrangements.length; i++) {
            if (result.arrangementsIds.indexOf(arrangements[i].objectId) === -1) {
                result.arrangementsIds.push(arrangements[i].objectId);
            }
            comp = arrangements[i].getComponents(ComponentConstants.ComponentType.Coating);
            if (comp) {
                for (j = 0; j < comp.length; j++) {
                    if (result.coatingIds.indexOf(comp[j]._coatingId) === -1) {
                        result.coatingIds.push(comp[j]._coatingId);
                    }
                }
            }
        }
        for (i = 0; i < joineries.length; i++) {
            comp = joineries[i].getComponent(ComponentConstants.ComponentType.JoineryType);
            if (comp) {
                if (result.joineryIds.indexOf(comp._joineryTypeId) === -1) {
                    result.joineryIds.push(comp._joineryTypeId);
                }
            }
        }
        for (i = 0; i < techElements.length; i++) {
            comp = techElements[i].getComponent(ComponentConstants.ComponentType.TechnicalElementType);
            if (comp) {
                if (result.technicalElementIds.indexOf(comp._technicalElementTypeId) === -1) {
                    result.technicalElementIds.push(comp._technicalElementTypeId);
                }
            }
        }

        return result;
    }

    getCoatingQuantity(coating: Component): number {
        let entity = coating.entity;
        switch (coating.componentType) {
            case ComponentConstants.ComponentType.Coating:
                switch (entity.entityType) {
                    case SceneConstants.EntityType.Wall:
                    case SceneConstants.EntityType.Room:
                    case SceneConstants.EntityType.GeometryPrimitive:
                    case SceneConstants.EntityType.WorkTop:
                    case SceneConstants.EntityType.TechnicalElement:
                    case SceneConstants.EntityType.ArrangementObject:
                        return entity.getCoatingQuantity(coating);
                }
                break;

            case ComponentConstants.ComponentType.CoatingArea:
            case ComponentConstants.ComponentType.Credence:
            case ComponentConstants.ComponentType.FloorCoatingArea:
                return entity.getCoatingQuantity(coating);
        }
        return 0;
    }

    addCoating(coating, array) {
        let i;

        for (i = 0; i < array.length; i++) {
            if (array[i].id === coating.coatingId) {
                break;
            }
        }

        let quantity = this.getCoatingQuantity(coating);
        if (i < array.length) {
            array[i].quantity += quantity;
            array[i].entityIds.push(coating.entity.id);
        } else {
            array.push({
                id: coating._coatingId,
                quantity: quantity,
                entityIds: [coating.entity.id],
            });
        }
    }

    getAdvancedAssetManagerIds(): any {
        let arrangements = this.arrangementObjects;
        let worktops = this.worktops;
        let coatings = this.coatings;
        let joineries = this.joineries;
        let techElements = this.technicalElements;
        let geoPrimitives = this.geometryPrimitives;
        let i, j, comp;
        let result = {
            arrangements: [],
            coatings: [],
            joineries: [],
            technicalElements: [],
        };

        for (i = 0; i < coatings.length; i++) {
            this.addCoating(coatings[i], result.coatings);
        }
        for (i = 0; i < arrangements.length; i++) {
            for (j = 0; j < result.arrangements.length; j++) {
                if (result.arrangements[j].id === arrangements[i].objectId) {
                    break;
                }
            }

            if (j < result.arrangements.length) {
                result.arrangements[j].quantity++;
                result.arrangements[j].entityIds.push(arrangements[i].id);
            } else {
                result.arrangements.push({
                    id: arrangements[i].objectId,
                    quantity: 1,
                    entityIds: [arrangements[i].id],
                });
            }
        }
        for (i = 0; i < joineries.length; i++) {
            comp = joineries[i].getComponent(ComponentConstants.ComponentType.JoineryType);
            if (comp) {
                for (j = 0; j < result.joineries.length; j++) {
                    if (result.joineries[j].id === comp._joineryTypeId) {
                        break;
                    }
                }

                if (j < result.joineries.length) {
                    result.joineries[j].quantity++;
                    result.joineries[j].entityIds.push(joineries[i].id);
                } else {
                    result.joineries.push({
                        id: comp._joineryTypeId,
                        quantity: 1,
                        entityIds: [joineries[i].id],
                    });
                }
            }
        }
        for (i = 0; i < techElements.length; i++) {
            comp = techElements[i].getComponent(ComponentConstants.ComponentType.TechnicalElementType);
            if (comp) {
                for (j = 0; j < result.technicalElements.length; j++) {
                    if (result.technicalElements[j].id === comp._technicalElementTypeId) {
                        break;
                    }
                }

                if (j < result.technicalElements.length) {
                    result.technicalElements[j].quantity++;
                    result.technicalElements[j].entityIds.push(techElements[i].id);
                } else {
                    result.technicalElements.push({
                        id: comp._technicalElementTypeId,
                        quantity: 1,
                        entityIds: [techElements[i].id],
                    });
                }
            }
        }

        return result;
    }

    // 0: Draw, 1: Construction, 2: Decoration
    timerTick(mode: number, CR: boolean, CRvisio: boolean) {
        let date = new Date();
        let time = date.getTime();
        let delta = time - this.lastTimerCall;
        this.lastTimerCall = time;

        // Above 30 minutes, do not count the action (we estimate the tool was left opened on the computer)
        if (delta > 30 * 60 * 1000) {
            return;
        }

        // Less than 6 minutes, production time
        if (delta < 6 * 60 * 1000) {
            switch (mode) {
                case 0:
                    if (CRvisio) {
                        this._activeDrawCRVisioTime += delta;
                    } else {
                        if (CR) {
                            this._activeDrawCRTime += delta;
                        } else {
                            this._activeDrawTime += delta;
                        }
                    }
                    break;
                case 1:
                    if (CRvisio) {
                        this._activeConstructionCRVisioTime += delta;
                    } else {
                        if (CR) {
                            this._activeConstructionCRTime += delta;
                        } else {
                            this._activeConstructionTime += delta;
                        }
                    }
                    break;
                case 2:
                    if (CRvisio) {
                        this._activeDecorationCRVisioTime += delta;
                    } else {
                        if (CR) {
                            this._activeDecorationCRTime += delta;
                        } else {
                            this._activeDecorationTime += delta;
                        }
                    }
                    break;
            }
            this._logActiveTime += delta;
        } else {
            // More than 6 minutes, consider idle time
            switch (mode) {
                case 0:
                    if (CRvisio) {
                        this._idleDrawCRVisioTime += delta;
                        this._idleDrawCRVisioDurations.push({
                            date: date.toString(),
                            duration: delta,
                        });
                    } else {
                        if (CR) {
                            this._idleDrawCRTime += delta;
                            this._idleDrawCRDurations.push({
                                date: date.toString(),
                                duration: delta,
                            });
                        } else {
                            this._idleDrawTime += delta;
                            this._idleDrawDurations.push({
                                date: date.toString(),
                                duration: delta,
                            });
                        }
                    }
                    break;
                case 1:
                    if (CRvisio) {
                        this._idleConstructionCRVisioTime += delta;
                        this._idleConstructionCRVisioDurations.push({
                            date: date.toString(),
                            duration: delta,
                        });
                    } else {
                        if (CR) {
                            this._idleConstructionCRTime += delta;
                            this._idleConstructionCRDurations.push({
                                date: date.toString(),
                                duration: delta,
                            });
                        } else {
                            this._idleConstructionTime += delta;
                            this._idleConstructionDurations.push({
                                date: date.toString(),
                                duration: delta,
                            });
                        }
                    }
                    break;
                case 2:
                    if (CRvisio) {
                        this._idleDecorationCRVisioTime += delta;
                        this._idleDecorationCRVisioDurations.push({
                            date: date.toString(),
                            duration: delta,
                        });
                    } else {
                        if (CR) {
                            this._idleDecorationCRTime += delta;
                            this._idleDecorationCRDurations.push({
                                date: date.toString(),
                                duration: delta,
                            });
                        } else {
                            this._idleDecorationTime += delta;
                            this._idleDecorationDurations.push({
                                date: date.toString(),
                                duration: delta,
                            });
                        }
                    }
                    break;
            }
            // > 10 minutes do not take it into account for idle
            if (delta < 10 * 60 * 1000) {
                this._logIdleTime += delta;
            }
        }
    }

    /**
     * Getter for the Entity type
     *
     */
    get entityType(): SceneConstants.EntityType {
        return SceneConstants.EntityType.World;
    }

    /*
    compare(otherWorld: World): any {
        let result = {
            wallEntityDiff: [],
            wallEntityDeleted: [],
            wallEntityAdded: [],
            joineryEntityDiff: [],
            joineryEntityDeleted: [],
            joineryEntityAdded: [],
            techElementEntityDiff: [],
            techElementEntityDeleted: [],
            techElementEntityAdded: [],
            stairCaseEntityDiff: [],
            stairCaseEntityDeleted: [],
            stairCaseEntityAdded: [],
            geomPrimitiveEntityDiff: [],
            geomPrimitiveEntityDeleted: [],
            geomPrimitiveEntityAdded: [],
        };

        for (let i = 0; i < this.children.length; ++i) {
            if (i < otherWorld.children.length) {
                let data = (this.children[i] as Scene).compare(otherWorld.children[i]);
                result.wallEntityDiff = result.wallEntityDiff.concat(data.wallEntityDiff);
                result.wallEntityDeleted = result.wallEntityDeleted.concat(data.wallEntityDeleted);
                result.wallEntityAdded = result.wallEntityAdded.concat(data.wallEntityAdded);
                result.joineryEntityDiff = result.joineryEntityDiff.concat(data.joineryEntityDiff);
                result.joineryEntityDeleted = result.joineryEntityDeleted.concat(data.joineryEntityDeleted);
                result.joineryEntityAdded = result.joineryEntityAdded.concat(data.joineryEntityAdded);
                result.techElementEntityDiff = result.techElementEntityDiff.concat(data.techElementEntityDiff);
                result.techElementEntityDeleted = result.techElementEntityDeleted.concat(data.techElementEntityDeleted);
                result.techElementEntityAdded = result.techElementEntityAdded.concat(data.techElementEntityAdded);
                result.stairCaseEntityDiff = result.stairCaseEntityDiff.concat(data.stairCaseEntityDiff);
                result.stairCaseEntityDeleted = result.stairCaseEntityDeleted.concat(data.stairCaseEntityDeleted);
                result.stairCaseEntityAdded = result.stairCaseEntityAdded.concat(data.stairCaseEntityAdded);
                result.geomPrimitiveEntityDiff = result.geomPrimitiveEntityDiff.concat(data.geomPrimitiveEntityDiff);
                result.geomPrimitiveEntityDeleted = result.geomPrimitiveEntityDeleted.concat(data.geomPrimitiveEntityDeleted);
                result.geomPrimitiveEntityAdded = result.geomPrimitiveEntityAdded.concat(data.geomPrimitiveEntityAdded);
            } else {
            }
        }

        return result;
    }*/
}
