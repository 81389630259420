export class Cleaner {

    static __cleanOneMaterial(material) : void {
        if (material.map) {
            if (!material.map.weakRef) {
                material.map.dispose();
            } else {
                material.map.weakRef.sharedRef--;
                if (material.map.weakRef.sharedRef === 0) {
                    material.map.dispose();
                }
                material.map.weakRef = null;
            }
        }
        if (material.normalMap) {
            if (!material.normalMap.weakRef) {
                material.normalMap.dispose();
            } else {
                material.normalMap.weakRef.sharedRef--;
                if (material.normalMap.weakRef.sharedRef === 0) {
                    material.normalMap.dispose();
                }
                material.normalMap.weakRef = null;
            }
        }
        if (material.bumpMap) {
            if (!material.bumpMap.weakRef) {
                material.bumpMap.dispose();
            } else {
                material.bumpMap.weakRef.sharedRef--;
                if (material.bumpMap.weakRef.sharedRef === 0) {
                    material.bumpMap.dispose();
                }
                material.bumpMap.weakRef = null;
            }
        }
        if (material.alphaMap) {
            if (!material.alphaMap.weakRef) {
                material.alphaMap.dispose();
            } else {
                material.alphaMap.weakRef.sharedRef--;
                if (material.alphaMap.weakRef.sharedRef === 0) {
                    material.alphaMap.dispose();
                }
                material.alphaMap.weakRef = null;
            }
        }
        material.dispose();
    }

    static cleanMaterial(material) : void {
        if (Array.isArray(material)) {
            for (var i = 0; i < material.length; ++i) {
                this.__cleanOneMaterial(material[i]);
            }
        } else {
            this.__cleanOneMaterial(material);
        }
    }

}