import * as glMatrix from 'gl-matrix'

export class Line {

    private A: glMatrix.vec3;
    private B: glMatrix.vec3;

    constructor(A: glMatrix.vec3, B: glMatrix.vec3) {
        this.A = glMatrix.vec3.fromValues(A[0], A[1], 0);
        this.B = glMatrix.vec3.fromValues(B[0], B[1], 0);
    }

    get D() : glMatrix.vec3 {
        var d = glMatrix.vec3.create();
        glMatrix.vec3.subtract(d, this.B, this.A);
        glMatrix.vec3.normalize(d, d);
        return d;
    }

    get Length() : number {
        var D = glMatrix.vec3.create();
        glMatrix.vec3.subtract(D, this.B, this.A);
        return glMatrix.vec3.length(D);
    }

    Intersect(L: Line) : glMatrix.vec3 | null {
        var dL = glMatrix.vec3.create();
        glMatrix.vec3.subtract(dL, L.B, L.A);

        var d = glMatrix.vec3.create();
        glMatrix.vec3.subtract(d, this.B, this.A);

        var ALA = glMatrix.vec3.create();
        glMatrix.vec3.subtract(ALA, L.A, this.A);
        var C1 = glMatrix.vec3.create();
        glMatrix.vec3.cross(C1, ALA, dL);
        var C2 = glMatrix.vec3.create();
        glMatrix.vec3.cross(C2, d, dL);
        
        var t = C1[2] / C2[2];
        if (!isFinite(t)) {
            return null;
        }

        return glMatrix.vec3.fromValues(
            this.A[0] + d[0] * t,
            this.A[1] + d[1] * t,
            0
        );
    }

    IsParallel(L) : boolean {
        var dL = glMatrix.vec3.create();
        glMatrix.vec3.subtract(dL, L.B, L.A);
        glMatrix.vec3.normalize(dL, dL);

        var d = glMatrix.vec3.create();
        glMatrix.vec3.subtract(d, this.B, this.A);
        glMatrix.vec3.normalize(d, d);

        if (Math.abs(glMatrix.vec3.dot(dL, d)) > 0.9999999) {
            return true;
        }

        return false;
    }

    Project(P: glMatrix.vec3) : glMatrix.vec3 {
        var C = glMatrix.vec3.create();
        var D = glMatrix.vec3.create();
        glMatrix.vec3.subtract(C, P, this.A);
        glMatrix.vec3.subtract(D, this.B, this.A);
        glMatrix.vec3.normalize(D, D);
        var a1 = glMatrix.vec3.dot(C, D);
        return glMatrix.vec3.fromValues(this.A[0] + a1 * D[0], this.A[1] + a1 * D[1], 0);
    }

    Distance(P: glMatrix.vec3) : number {
        var C = this.Project(P);
        var D = glMatrix.vec3.create();
        glMatrix.vec3.subtract(D, P, C);
        return glMatrix.vec3.length(D);
    }

    BelongToSegment(C: glMatrix.vec3) : boolean {
        if (!C) {
            return false;
        }

        var tC = glMatrix.vec3.fromValues(C[0], C[1], 0);
        var AB = glMatrix.vec3.create();
        glMatrix.vec3.subtract(AB, this.B, this.A);
        var AC = glMatrix.vec3.create();
        glMatrix.vec3.subtract(AC, tC, this.A);
        var Kac = glMatrix.vec3.dot(AB, AC);
        var Kab = glMatrix.vec3.dot(AB, AB);
        return Kac >= 0 && Kac <= Kab;
    }

}