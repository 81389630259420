import * as SavaneJS from '@rhinov/savane-js';
import async from 'async-es';
import { ReplaceArrangementCommand } from './ReplaceArrangementCommand';

declare var AssetManagerServices;
declare var Savane;

export class ChangeKitchenStyleCommand extends SavaneJS.Commands.Command {
    private _handler: any;
    private _arrangements: any;
    private _style: any;
    private _coatingId: any;
    private _commands: any[];

    constructor(handler, arrangements, style, coatingId) {
        super();

        this._handler = handler;
        this._arrangements = arrangements;
        this._style = style;
        this._coatingId = coatingId;
        this._commands = [];
    }

    name() {
        return "ChangeKitchenStyleCommand";
    }

    assetType() {
        return AssetManagerServices._ASSET_TYPE.ARRANGEMENTS;
    }

    undo() {
        for (var i = this._commands.length - 1; i >= 0; --i) {
            this._commands[i].undo();
        }

        SavaneJS.KitchenTool.KitchenManager.setFurnitureFinishes(this._arrangements[0].floor);
        Savane.eventsManager.instance.dispatch(SavaneJS.Events.PROJECT_REFRESH_HULL);
        this._handler.scene.render();
        Savane.eventsManager.instance.dispatch(SavaneJS.Events.RHINOV_FORMAT_UPDATED, { scene: this._arrangements[0].scene, command: null });
    }

    redo() {
        this.execute(true);
    }

    execute(redo) {
        this._commands = [];

        AssetManagerServices.changeKitchenStyle(this._arrangements, this._style, this._coatingId, function (result) {
            if (this._arrangements.length === 0) return;

            var addedEntity = null;
            for (var i = 0; i < this._arrangements.length; i++) {
                var oldArrangement = this._arrangements[i];

                if (oldArrangement.isArrangementObjectEntity()) {
                    var entity = result[i].new_object_entity;

                    // No entity tu substitute, continue loop
                    if (!entity) {
                        continue;
                    }

                    // keep ids
                    entity._id = oldArrangement._id;
                    if (this._handler.scene.outlinePass) {
                        this._handler.scene.outlinePass.selectedObjects = [];
                        this._handler.selectedNode = [];
                    }

                    addedEntity = entity;
                    this._commands.push(new ReplaceArrangementCommand(this._handler, oldArrangement, entity, true));
                }
            }

            async.eachSeries(this._commands, function(command, callback) {
                command.execute(true, function() {
                    callback();
                })
            }, function() {
                SavaneJS.KitchenTool.KitchenManager.setFurnitureFinishes(addedEntity.floor);
                Savane.eventsManager.instance.dispatch(SavaneJS.Events.PROJECT_REFRESH_HULL);
                this._handler.scene.render();
                Savane.eventsManager.instance.dispatch(SavaneJS.Events.RHINOV_FORMAT_UPDATED, { scene: addedEntity.scene, command: redo ? null : this, newKitchenStyleInfos: result });
            }.bind(this));
        }.bind(this));
    }

}
