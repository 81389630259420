import * as THREE from 'three';
import * as SavaneJS from '@rhinov/savane-js';
import { StaticHull } from './hulls/staticHull';
import { FloorGeneratorHull } from './hulls/floorGeneratorHull';
import { DynamicHull } from './hulls/dynamicHull';
import { WebglScene } from './scene';
import { Cleaner } from './helpers/cleaner'

declare var EntityManager;

export class WebglHullEntity {

    public entity: SavaneJS.Entity;
    public startManipulation: boolean = false;
    public object: THREE.Mesh | null;
    public hull: StaticHull | FloorGeneratorHull | DynamicHull;
    
    private scene: WebglScene;
    private width: number;
    private height: number;
    private length: number;
    private dummy: boolean;

    constructor(entity: SavaneJS.Entity, scene: WebglScene, object: THREE.Mesh, hull: StaticHull | FloorGeneratorHull | DynamicHull, cloned: boolean, dummy: boolean) {
        // Keep track of the savane entity
        this.entity       = entity;
        this.object       = object;
        this.hull         = hull;
        this.scene        = scene;

        this.width        = (this.entity as any).width;
        this.height       = (this.entity as any).height;
        this.length       = (this.entity as any).length;
        this.dummy        = dummy;
    
        if (entity.isGeometryPrimitiveEntity()) {
            if ((this.entity as any).width === undefined) {
                this.width = (this.entity as any).diameter;
                this.length = (this.entity as any).diameter;
            }
        }
    
        if (!cloned && !this.dummy) {
            var globalMatrix = this.entity.transform.globalMatrix;
            var translation = new THREE.Matrix4().makeTranslation(-globalMatrix[12] / 100, -globalMatrix[13] / 100, -globalMatrix[14] / 100);
            this.object.geometry.applyMatrix4(translation);
    
            var Xi = new THREE.Vector3().set(globalMatrix[0], globalMatrix[1], globalMatrix[2]).normalize();
            var Yi = new THREE.Vector3().set(globalMatrix[4], globalMatrix[5], globalMatrix[6]).normalize();
            var Zi = new THREE.Vector3().set(globalMatrix[8], globalMatrix[9], globalMatrix[10]).normalize();
            var initialBasis = new THREE.Matrix4().makeBasis(Xi, Yi, Zi).transpose();
            this.object.geometry.applyMatrix4(initialBasis);
        }
        
        this.update();
    }

    update() {
        if (this.dummy) return;
        // Update THREE matrix based on savane matrix datas
        var globalMatrix = this.entity.transform.globalMatrix;

        // Convert matrix to three space and apply to object
        var X = new THREE.Vector3().set(globalMatrix[0], globalMatrix[1], globalMatrix[2]).normalize();
        var Y = new THREE.Vector3().set(globalMatrix[4], globalMatrix[5], globalMatrix[6]).normalize();
        var Z = new THREE.Vector3().set(globalMatrix[8], globalMatrix[9], globalMatrix[10]).normalize();

        var basis = new THREE.Matrix4().makeBasis(X, Y, Z);
        var dummy = new THREE.Vector3();
        var quaternion = new THREE.Quaternion();
        basis.decompose(dummy, quaternion, dummy);

        this.object!.quaternion.copy(quaternion);
        this.object!.position.set(globalMatrix[12] / 100, globalMatrix[13] / 100, (globalMatrix[14]) / 100);

        var entityLength = (this.entity as any).length;
        var entityWidth = (this.entity as any).width;
        if (this.entity.isGeometryPrimitiveEntity()) {
            if ((this.entity as any).width === undefined) {
                entityLength = (this.entity as any).diameter;
                entityWidth = (this.entity as any).diameter;
            }
        }
        var entityHeight = (this.entity as any).height;
        var scale = new THREE.Matrix4().makeScale(entityLength / this.length, entityWidth / this.width, entityHeight / this.height);
        this.object!.geometry.applyMatrix4(scale);
        this.object!.scale.copy(new THREE.Vector3(1, 1, 1));
        this.length = entityLength;
        this.width = entityWidth;
        this.height = entityHeight;

        this.scene.updateEnvs();
        if ((this.object as any).body) {
            (this.object as any).body.needUpdate = true;
        }
    }

    highlight(val) {
    }

    /**
     * Apply back a change performed in the webGL view into the savane entity
     **/
    applyToEntity(mode: string, space: string) {
        if (this.dummy) return;
        var scale = this.object!.scale;
        var invScale = new THREE.Vector3(1 / scale.x, 1 / scale.y, 1 / scale.z);
        // Make a safe copy
        var mat = new THREE.Matrix4();
        mat.copy(this.object!.matrix);
        mat.scale(invScale);

        var parentHeight = 0;
        if (this.entity.floor) {
            parentHeight = this.entity.floor.height;
        }

        var array = new Array<number>();
        mat.toArray(array);
        var height = array[14] * 100 - parentHeight;

        if (mode === 'rotate') {
            if (space === 'local') {
                array[12] = this.entity.position[0] / 100;
                array[13] = this.entity.position[1] / 100;
                this.object!.position.x = array[12];
                this.object!.position.y = array[13];
            }
            var savaneMatrix = SavaneJS.math.mat4.create();
            SavaneJS.math.mat4.set(savaneMatrix,
                array[0], array[1], array[2], array[3],
                array[4], array[5], array[6], array[7],
                array[8], array[9], array[10], array[11],
                array[12] * 100, array[13] * 100, height, array[15]);
            this.entity.transform.localMatrix = savaneMatrix;
        }
        else if (mode === 'translate') {
            this.entity.transform.localPosition = [array[12] * 100, array[13] * 100, height];
        } else {
            if (this.entity.isGeometryPrimitiveEntity()) {
                if ((this.entity as any).width === undefined) {
                    (this.entity as any).diameter = Math.max(1, this.length * Math.abs(scale.x));
                } else {
                    (this.entity as any).length = Math.max(1, this.length * Math.abs(scale.x));
                    (this.entity as any).width = Math.max(1, this.width * Math.abs(scale.y));
                }
            } else {
                (this.entity as any).length = Math.max(1, this.length * Math.abs(scale.x));
                (this.entity as any).width = Math.max(1, this.width * Math.abs(scale.y));
            }
            
            var newHeight = Math.max(1, this.height * Math.abs(scale.z));
            var deltaHeight = (newHeight - (this.entity as any).height) / 2;
            (this.entity as any).height = newHeight;

            var savaneMatrix = SavaneJS.math.mat4.create();
            SavaneJS.math.mat4.set(savaneMatrix,
                array[0], array[1], array[2], array[3],
                array[4], array[5], array[6], array[7],
                array[8], array[9], array[10], array[11],
                array[12] * 100, array[13] * 100, height, array[15]);

            this.entity.floorHeight -= deltaHeight;
            this.entity.transform.localMatrix = savaneMatrix;
        }

        if (typeof EntityManager !== 'undefined') {
            var node = EntityManager.getNode(this.entity);
            if (node !== null)
            {
                node.needRedraw = true;
            }
        }
        this.update();
    }

    enableCollision(scale) {
        if (this.dummy) return;
        var traversing = function(child) {
            if (!child.isMesh || !child.name) return;
            if (scale && child.body) {
                this.scene.physics.destroy(child.body);
            }
            if (child.body) return;
            this.scene.physics.add.existing(child, {shape: 'mesh'});
            var box = new THREE.Box3().setFromObject(child);
            var size = new THREE.Vector3();
            box.getSize(size);
            var radius = Math.max(size.x, Math.max(size.y, size.z));
            child.body.setCcdMotionThreshold(radius);
            child.body.setCcdSweptSphereRadius(radius / 5);
            child.body.setCollisionFlags(6);
            child.body.on.collision(function(otherObject, event) {
                if (!this.object) return;
                if (this.object.getObjectById(otherObject.id)) {
                    return;
                }
                if (this.startManipulation && otherObject.body) {
                    this.scene.physics.destroy(otherObject.body);
                    otherObject.ignoreCollisions = true;
                    return;
                }
                if (event === 'start' || event === 'collision') {
                    this.scene.detachSelection();
                    this.update();
                    this.scene.attachSelection();
                }
            }.bind(this));
        }.bind(this);

        this.object!.traverse(traversing);
    }

    dispose() {
        // dispose GL resources
        this.object!.traverse(function(child) {
            if (child.geometry) {
                child.geometry.dispose();
            }
            if (child.material) {
                Cleaner.cleanMaterial(child.material);
            }
            if (child.body) {
                this.scene.physics.destroy(child.body);
            }
        }.bind(this));

        // Free THREE objects
        this.object = null;
    }
}
