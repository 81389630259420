import { PLAN_WEBGL_MODULE_PATH, CAMERA_STATES } from "../constants";
import { WebGLController } from "./webgl-controller";

export class ElementWrapper {

    private element: HTMLElement;

    constructor(element) {
        this.element = element;
    }

    appendChild(childElement) {
        this.element.appendChild(childElement);
        return this;
    }

    after(siblingElement) {
        this.element.parentNode!.insertBefore(siblingElement, this.element.nextSibling);
        return this;
    }

    getValue() {
        return this.element;
    }

}

export class WebGLUi {

    private _dom: HTMLElement;
    private _controller: WebGLController;
    private _bindedElement: HTMLElement;
    private _displaySettings: boolean;
    private CAMERA_STATES = CAMERA_STATES;

    // BEGIN: getters and setters

    get dom() {
        return this._dom;
    }

    set dom(value) {
        this._dom = value;
    }

    get thirdGrid() {
        return this._controller.thirdGrid;
    }

    set thirdGrid(newValue) {
        this._controller.thirdGrid = newValue;
    }

    get loaded() {
        return this._controller.loaded;
    }

    set loaded(newValue) {
        this._controller.loaded = newValue;
    }

    get zoom() {
        return this._controller.zoom;
    }

    set zoom(newValue) {
        this._controller.zoom = newValue;
    }

    get jsonScene() {
        return this._controller.jsonScene;
    }

    set jsonScene(value) {
        this._controller.jsonScene = value;
    }

    get activeCameraId() {
        return this._controller.activeCameraId;
    }

    set activeCameraId(value) {
        this._controller.activeCameraId = value;
    }

    get entity() {
        return this._controller.entity;
    }

    set entity(value) {
        this._controller.entity = value;
    }

    get entities() {
        return this._controller.entities;
    }

    set entities(value) {
        this._controller.entities = value;
    }

    get config() {
        return this._controller.config;
    }

    set config(value) {
        this._controller.config = value;
    }

    get lod() {
        return this._controller.lod;
    }

    set lod(value) {
        this._controller.lod = value;
    }

    get imgProject() {
        return this._controller.imgProject;
    }

    set imgProject(value) {
        this._controller.imgProject = value;
    }

    get leftPanel() {
        return this._controller.leftPanel;
    }

    set leftPanel(value) {
        this._controller.leftPanel = value;
    }

    get deliverable() {
        return this._controller.deliverable;
    }

    set deliverable(value) {
        this._controller.deliverable = value;
    }

    get deliverableId() {
        return this._controller.deliverableId;
    }

    set deliverableId(value) {
        this._controller.deliverableId = value;
    }

    get user() {
        return this._controller.user;
    }

    set user(value) {
        this._controller.user = value;
    }

    get controls() {
        return this._controller.controls;
    }

    set controls(value) {
        this._controller.controls = value;
    }

    get useCameraRatio() {
        return this._controller.useCameraRatio;
    }

    set useCameraRatio(value) {
        this._controller.useCameraRatio = value;
    }

    get noFocus() {
        return this._controller.noFocus;
    }

    set noFocus(newValue) {
        this._controller.noFocus = newValue;
    }

    get settings() {
        return this._controller.settings;
    }

    set settings(value) {
        this._controller.settings = value;
    }
    
    // END: getters and setters

    _elementsDisplayRules: Map<string, string> = new Map();
    
    constructor() {
        
        const delegate = {
            render: this._render.bind(this),
        };
        this._controller = new WebGLController(delegate);
    }

    destroy() {
        this._controller.destroy();
        if (this._bindedElement) {
            this._bindedElement.replaceChildren();
        }
    }

    resize() {
        if (this._bindedElement) {
            this._controller._resize();
        }
    }

    evaluate(value) {
        return eval(value);
    }

    run(parentElement, callback) {

        this._bindedElement = parentElement;
        this._bindedElement.appendChild(this._buildStyles());
        this._buildHtml();
        this._bindedElement.appendChild(this.dom);
        setTimeout(() => {
            this._controller.run(this._bindedElement, this.dom, callback);
            this._render();
        });
    }

    _buildHtml() {

        this._buildContainerHtml();
        this._buildThirdGridHtml();
        this._buildLoadedHtml();
        this._buildTextCanvasHtml();
        this._buildCameraParametersHtml();
        this._buildDetailsSliderHtml();
        this._buildDisplayQualityHtml();
        this._buildAmSwitchHtml();
        this._buildFullScreenHtml();
        this._buildFreeCameraHtml();
        this._buildGizmoSpaceHtml();
        this._buildTogglePhysicsHtml();
        this._buildUpdateHullsHtml();
        this._buildPhotoFromProjectHtml();
        
    }

    _buildStyles() {

        const style = this._createElement('style', null, null, {
            type: 'text/css'
        });

        style.appendChild(document.createTextNode(`
            .webgl-camera {
                color: black;
            }
        
            .webgl-camera.webgl-camera-active {
                color: yellow;
            }
        
            .webgl-quality {
                color: black;
            }
        
            .webgl-quality.webgl-quality-active {
                color: yellow;
            }

            .selectBox {
                border: 1px solid #55aaff;
                background-color: rgba(75, 160, 255, 0.3);
                position: fixed;
            }

            #plan-webgl-rt > * {
                display: none;
            }

            #plan-webgl-rt {

                #webglcanvas {
                    display: block !important;
                }

                &.third-grid-html {
                    #third-grid-html {
                        display: block !important;
                    }
                }

                &.loaded-html {
                    #loaded-html {
                        display: block;
                    }
                }

                &.text-canvas-html {
                    #text-canvas-html {
                        display: block !important;
                    }
                }
                
                &.camera-parameters-html {
                    #camera-parameters-html {
                        display: block !important;
                    }
                }
                
                &.details-slider-html {
                    #details-slider-html {
                        display: block !important;
                    }
                }
                
                &.display-quality-html {
                    #display-quality-html {
                        display: block !important;
                    }
                }

                &.am-switch-html {
                    #am-switch-html {
                        display:flex;
                    }
                }

                &.full-screen1-html {
                    #full-screen1-html {
                        display: block !important;
                    }
                }
                
                &.full-screen2-html {
                    #full-screen2-html {
                        display: block !important;
                    }
                }

                &.free-camera-html {
                    #free-camera-html {
                        display: flex !important;
                    }
                }

                &.gizmo-space-html {
                    #gizmo-space-html {
                        display: block !important;
                    }
                }

                &.toggle-physics-html {
                    #toggle-physics-html {
                        display: block !important;
                    }
                }

                &.update-hulls-html {
                    #update-hulls-html {
                        display: block !important;
                    }
                }

                &.photo-from-project-html {
                    #photo-from-project-html {
                        display: block !important;
                    }
                }
            }
        `));

        return style;

    }

    _buildContainerHtml() {

        this.dom = this._createElement('div', 'plan-webgl-rt', "width:100%;height:100%;position:relative;margin:auto;outline:none;user-select:none;overflow: hidden;");
        this.dom.tabIndex = 1;

    }

    _buildThirdGridHtml() {

        const id = 'third-grid-html';
        const wrapper = new ElementWrapper(this._createElement('div', id, "position: absolute; width: 100%; height: 100%; pointer-events: none;"));

        this.dom.appendChild(wrapper.getValue());
        this._elementsDisplayRules[id] = 'this.thirdGrid';

        wrapper
            .appendChild(this._createElement('div', null, "position: absolute; top: 33.3333%; border-top: 1.1px solid black; width: 100%"))
            .appendChild(this._createElement('div', null, "position: absolute; top: 50%; border-top: 1.1px solid rgb(80, 80, 80); width: 100%"))
            .appendChild(this._createElement('div', null, "position: absolute; top: 66.6666%; border-top: 1.1px solid black; width: 100%"))
            .appendChild(this._createElement('div', null, "position: absolute; left: 33.3333%; border-left: 1.1px solid black; height: 100%"))
            .appendChild(this._createElement('div', null, "position: absolute; left: 50%; border-left: 1.1px solid rgb(80, 80, 80); height: 100%"))
            .appendChild(this._createElement('div', null, "position: absolute; left: 66.6666%; border-left: 1.1px solid black; height: 100%"));

    }

    _buildLoadedHtml() {

        const id = 'loaded-html';
        const element = this._createElement('div', id, "position: absolute; width: 100%; height: 100%; pointer-events: all; background-color: rgba(0, 0, 0, 0.8);");

        this.dom.appendChild(element);

        this._elementsDisplayRules[id] = 'this._controller.loaded === false';

        const lastAddedElement = element
            .appendChild(this._createElement('div', null, "margin: 0; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 100%;"))
            .appendChild(this._createElement('div', null, "text-align: center;"));

        const imageTag = this._createElement('img');
        imageTag.src = PLAN_WEBGL_MODULE_PATH + '/medias/loader.gif';

        lastAddedElement.appendChild(imageTag);

        const firstLoadingText = this._createElement('div');
        const secondLoadingText = this._createElement('div');

        firstLoadingText.classList.add("fabrikat");
        secondLoadingText.classList.add("valentine");

        firstLoadingText.textContent = "Votre projet est en cours de chargement...";
        secondLoadingText.textContent = "Cela peut prendre quelques minutes, merci de patienter";

        new ElementWrapper(lastAddedElement)
            .after(firstLoadingText)
            .after(secondLoadingText);

    }

    _buildTextCanvasHtml() {

        const id = 'text-canvas-html';
        const wrapper = new ElementWrapper(this._createElement('div', id, "position: absolute; width: 100%; height: 100%; pointer-events: none;z-index: 2;"));

        this.dom.appendChild(wrapper.getValue());

        this._elementsDisplayRules[id] = 'this._controller.displayHeight() && !(this.entity || this.entities) && this.zoom === 1';

        wrapper.appendChild(this._createElement('canvas', 'plan-webgl-rt-text-canvas', "width: 100%; height: 100%; pointer-events: none;"));

    }

    _buildCameraParametersHtml() {

        const id = 'camera-parameters-html';
        const wrapper = new ElementWrapper(this._createElement('div', id, "position: absolute; right: 6px; top: 0px; pointer-events: none; font-weight: 600;z-index: 2;"));

        this.dom.appendChild(wrapper.getValue());

        this._elementsDisplayRules[id] = 'this._controller.displayCameraParameters() && !(this.entity || this.entities) && this.zoom === 1';

        const firstSpan = this._createElement('span', null, "color: black;");
        firstSpan.dynTextContent = 'Hauteur: {{this._controller.cameraHeight}}cm ';

        const secondSpan = this._createElement('span', null, "color: black;");
        secondSpan.dynTextContent = 'Focal: {{this._controller.cameraFocal}}°';

        wrapper
            .appendChild(firstSpan)
            .appendChild(secondSpan);

    }

    _buildDetailsSliderHtml() {

        const id = 'details-slider-html';
        const wrapper = new ElementWrapper(this._createElement('div', id, "position:absolute;right:40px;bottom:4px;display:flex;z-index: 2;"));

        this.dom.appendChild(wrapper.getValue());

        this._elementsDisplayRules[id] = 'this.controls && !(this.entity || this.entities) && this.zoom === 1';

        this._displaySettings = false;
        const icon = this._createElement('i', null, "color:black;cursor:pointer;");

        icon.addEventListener("click", () => {
            this._displaySettings = !this._displaySettings;
            this._render();
        });
        icon.classList.add("fas");
        icon.classList.add("fa-cogs");

        wrapper.appendChild(icon);

    }

    _buildDisplayQualityHtml() {

        const id = 'display-quality-html';
        const wrapper = new ElementWrapper(this._createElement('div', id, "position: absolute; bottom: 0; padding: 10px; left: 25%; width: 50%; z-index: 10; background-color: rgba(0, 0, 0, 0.75);"));

        this.dom.appendChild(wrapper.getValue());

        this._elementsDisplayRules[id] = 'this._displaySettings';

        const firstContainer = this._createElement('div', null, "width: 50%; float: left; font-size: 12px");
        const secondContainer = this._createElement('div', null, "width: 50%; float: right; font-size: 12px");

        wrapper
            .appendChild(firstContainer)
            .appendChild(secondContainer);

        const firstIds = [{id: 'settings-uvlp', text: 'Qualité basse'},
            {id: 'settings-vlp', text: 'Qualité normale'},
            {id: 'settings-ls', text: 'Qualité haute'}];

        for (let i = 0; i < firstIds.length; i++) {
            const radioWrapper = this._createElement('div');
            firstContainer.appendChild(radioWrapper);

            const radioLabel = this._createElement('label', null, "color: white;", {
                'for': firstIds[i].id
            });
            radioLabel.textContent = firstIds[i].text;

            const radioInput = this._createElement('input', firstIds[i].id, null, {
                'type': 'radio',
                'name': 'meshLevel',
                'value': i.toString(),
                'bind-model': 'settings.meshLevel',
            });
            radioInput.addEventListener('change', (event) => {
                this._controller.meshLevel = parseInt(event.target.value);
                this._controller._updateSettings();
                this._render();
            });

            new ElementWrapper(radioWrapper)
                .appendChild(radioInput)
                .appendChild(radioLabel);
        }

        const secondIds = [{id : 'settings-hullbin', model: 'settings.hullbin', text: 'Coque rapide'},
            {id: 'settings-aliasing', model: 'settings.fxaa', text: 'Anti-aliasing'},
            {id:'settings-env', model: 'settings.environement', text: 'Calcul des environements'},

            {id:'settings-mirrors', model: 'settings.mirrors', text: 'Miroir'}];
        for (let i = 0; i < secondIds.length; i++) {
            const checkboxWrapper = this._createElement('div');
            secondContainer.appendChild(checkboxWrapper);

            const checkboxLabel = this._createElement('label', null, "color: white;", {
                'for': secondIds[i].id
            });
            checkboxLabel.textContent = secondIds[i].text;

            const checkboxInput = this._createElement('input', secondIds[i].id, null, {
                'type': 'checkbox',
                'bind-model': secondIds[i].model,
            });
            checkboxInput.addEventListener('change', (event) => {
                this.evaluate.call(this, 'this.' + secondIds[i].model + ' = event.target.checked');
                this._controller._updateSettings();
                this._render();
            });

            new ElementWrapper(checkboxWrapper)
                .appendChild(checkboxInput)
                .appendChild(checkboxLabel);
        }

    }

    _buildAmSwitchHtml() {

        const id = 'am-switch-html';
        const element = this._createElement('div', id, "position:absolute;right:10px;bottom:10px;flex-direction: column;background-color: rgba(0, 0, 0, 0.75); padding: 5px; border-radius: 5px;");

        this.dom.appendChild(element);

        this._elementsDisplayRules[id] = 'this.entity || this.entities';

        // Add radio buttons

        const radioIds = ['lit', 'wireframe', 'normals', 'uvs', 'uvs checker', 'uvs lines'];

        for (let i = 0; i < radioIds.length; i++) {
            const radioWrapper = this._createElement('div');
            element.appendChild(radioWrapper);

            const radioLabel = this._createElement('label', null, "color: white;", {
                'for': radioIds[i]
            });
            radioLabel.textContent = radioIds[i].charAt(0).toUpperCase() + radioIds[i].slice(1);

            const radioInput = this._createElement('input', radioIds[i], null, {
                'type': 'radio',
                'name': 'display',
                'value': i.toString(),
                'bind-model': 'settings.display',
            });
            radioInput.addEventListener('change', (event) => {
                this.settings = {
                    ...this.settings,
                    display: parseInt(event.target.value)
                };
            });

            new ElementWrapper(radioWrapper)
                .appendChild(radioInput)
                .appendChild(radioLabel);

        }

    }

    _buildFullScreenHtml() {
        let id = 'full-screen1-html';
        const element = this._createElement('div', id, 'position:absolute;top:2px;left:8px;z-index: 2;');

        this.dom.appendChild(element);

        this._elementsDisplayRules[id] = "this.controls && !(this.entity || this.entities) && this.zoom === 1";

        const icon = this._createElement('i', null, "color:black;cursor:pointer;");

        icon.addEventListener("click", (event) => {
            this._controller._fullScreen(event);
            this._render();
        });

        icon.classList.add("glyphicon");
        icon.classList.add("glyphicon-fullscreen");

        element.appendChild(icon);

        id = 'full-screen2-html';
        const wrapper = new ElementWrapper(this._createElement('div', id, "position:absolute;top:2px;left:30px;z-index: 2;"));
        
        this.dom.appendChild(wrapper.getValue());

        this._elementsDisplayRules[id] = "this.controls && !(this.entity || this.entities) && this.zoom === 1";

        id = 'glDisplayCurrentFloor';
        const input = this._createElement('input', id, null, {
            'type': 'checkbox',
            'bind-model': '_controller._displayCurrentFloor',
        });
        input.addEventListener('change', (event) => {
            this._controller.displayCurrentFloor = event.target.checked;
            this._controller._displayCurrentFloorChanged(this._controller.displayCurrentFloor);
            this._render();
        });

        const label = this._createElement('label', null, null, {
            'for': id,
        });
        label.textContent = "Afficher l'étage courant";

        wrapper
            .appendChild(input)
            .appendChild(label);

    }

    _buildFreeCameraHtml() {
        const id = 'free-camera-html';
        const wrapper = new ElementWrapper(this._createElement('div', id, "position:absolute;top:24px;left:8px;flex-direction:row;z-index: 2;"));

        this.dom.appendChild(wrapper.getValue());

        this._elementsDisplayRules[id] = "this._controller._cameraState !== this.CAMERA_STATES.FRONT && !this._controller._isFullscreen && this.controls && !(this.entity || this.entities) && this.zoom === 1";

        const element = this._createElement('div', null, "font-weight:800;cursor:pointer;", {
            'dyn-class': "{ 'webgl-camera-active': this._controller._cameraState === this.CAMERA_STATES.FREE }",
        });
        element.addEventListener('mousedown', (event) => {
            this._controller._setFreeCamera(event);
            this._render();
        });

        element.classList.add("webgl-camera");
        element.textContent = "FREE";

        wrapper.appendChild(element);

    }

    _buildGizmoSpaceHtml() {
        const id = 'gizmo-space-html';

        const element = this._createElement('div', id, "position:absolute;top:24px;right:14px;display:flex;flex-direction:row;z-index: 2;");

        this.dom.appendChild(element);

        this._elementsDisplayRules[id] = "this._controller._cameraState !== this.CAMERA_STATES.FRONT && this.controls && this._controller._gizmoEnabled() && this.zoom === 1";

        const icon = this._createElement('i', null, null, {
            'dyn-style': "color: {{this._controller._gizmoSpace() === 'world' ? 'green' : 'black'}};cursor:pointer;"
        });

        icon.classList.add("fas");
        icon.classList.add("fa-lg");
        icon.classList.add("fa-globe-europe");

        icon.addEventListener("mousedown", (event) => {
            this._controller._changeGizmoSpace(event);
            this._render();
        });

        element.appendChild(icon);
    }

    _buildTogglePhysicsHtml() {
        const id = 'toggle-physics-html';

        const element = this._createElement('div', id, "position:absolute;top:24px;right:42px;display:flex;flex-direction:row;z-index: 2;");

        this.dom.appendChild(element);

        this._elementsDisplayRules[id] = "this.controls && this._controller._gizmoEnabled() && this.zoom";

        const icon = this._createElement('i', null, null, {
            'dyn-style': "color: {{this._controller._physicEnabled() ? 'green' : 'red'}};cursor:pointer;"
        });

        icon.classList.add("fas");
        icon.classList.add("fa-lg");
        icon.classList.add("fa-car-crash");

        icon.addEventListener("mousedown", (event) => {
            this._controller._togglePhysics(event);
            this._render();
        });

        element.appendChild(icon);
    }

    _buildUpdateHullsHtml() {

        const id = "update-hulls-html";

        const element = this._createElement('div', id, "position:absolute;right:8px;bottom:4px;display:flex;z-index: 2;");

        this.dom.appendChild(element);

        this._elementsDisplayRules[id] = "this.controls && !(this.entity || this.entities) && this.zoom === 1";

        const icon = this._createElement('i', null, "color:black;cursor:pointer;");

        icon.classList.add("fas");
        icon.classList.add("fa-sync-alt");
    
        icon.addEventListener("mousedown", (event) => {
            this._controller._updateHulls(event);
            this._render();
        });

        element.appendChild(icon);

    }

    _buildPhotoFromProjectHtml() {
        const id = "photo-from-project-html";

        const element = this._createElement('div', id, "position:absolute;top:0px;left:0px;bottom:0px;width:100%;pointer-events: none;z-index: 1;");

        this.dom.appendChild(element);

        this._elementsDisplayRules[id] = "this._controller.displayPhoto";

        element.appendChild(this._createElement('img', 'photoDisplay', null, {
            'dyn-style': "opacity: {{'' + this._controller.photoOpacity}};width: 100%;height:100%;transform: scale({{this.zoom}}); transform-origin: {{this._controller.transformOrigin}};",
            'dyn-src': "{{this._controller._getOriginalPhotoUrl()}}"
        }));
    }

    _buildResetZoomHtml() {
        const id = "reset-zoom-html";

        const element = this._createElement('div', id, "position:absolute;top:2px;left:8px;z-index: 2;");

        this.dom.appendChild(element);

        this._elementsDisplayRules[id] = "this.zoom !== 1";

        const icon = this._createElement('i', null, "color:black;cursor:pointer;");

        icon.classList.add("glyphicon");
        icon.classList.add("glyphicon-refresh");

        icon.addEventListener("mousedown", () => {
            this._controller._resetZoom();
        });

        element.appendChild(icon);
    }

    _createElement(tagName, id?, styles?, attributes?) {
        if (!tagName) {
            return;
        }

        const res = document.createElement(tagName);

        if (id) {
            res.id = id;
        }

        if (styles) {
            res.style.cssText = styles;
        }

        if (attributes) {
            Object.entries(attributes)
                .forEach((attribute) => {
                    res.setAttribute(attribute[0], attribute[1]);
                });
        }

        return res;
    }

    _render() {
        if (!this.dom) {
            return;
        }

        // Hide or show elements
        Object
            .entries(this._elementsDisplayRules)
            .forEach((entry) => {
                if (this.evaluate.call(this, entry[1])) {
                    this.dom.classList.add(entry[0]);
                } else {
                    this.dom.classList.remove(entry[0]);
                }
            });

        // Trigger models
        this.dom.classList.forEach((id) => {
            const element = document.getElementById(id);
            if (element) {
                const inputNodes = element.querySelectorAll('input');
                const inputList = [...inputNodes];
                inputList.forEach((input) => {
                    const value = input.getAttribute('bind-model');
                    if (value) {
                        if (input.getAttribute('type') === 'radio') {
                            if (this.evaluate.call(this, 'this.' + value) == input.getAttribute('value')) {
                                input.checked = true;
                            }
                        } else if (input.getAttribute('type') === 'checkbox') {
                            input.checked = !!this.evaluate.call(this, "this." + value);
                        }
                    }
                });
            }
        });

        // Replace {{everything between}}
        this._executeOnHtml(this.dom, (element) => {
            const dynClass = element.getAttribute && element.getAttribute('dyn-class');
            let dynStyle = element.getAttribute && element.getAttribute('dyn-style');
            let dynSrc = element.getAttribute && element.getAttribute('dyn-src');
            if (element.dynTextContent) {
                const matches = element.dynTextContent.match(/\{\{(.*?)\}\}/gs);
                if (matches) {
                    matches.forEach((toEval) => {
                        element.textContent = element.dynTextContent.replace(toEval, this.evaluate.call(this, toEval.slice(2, -2)));
                    });
                }
            }
            // 'dyn-class' attribute alows to add css classes dynamicaly
            if (dynClass) {
                const computedClasses = this.evaluate.call(this, '(' + dynClass + ')');
                Object.keys(computedClasses).forEach((key) => {
                    if (computedClasses[key]) {
                        element.classList.add(key);
                    } else {
                        element.classList.remove(key);
                    }
                });
            }
            // 'dyn-style' attribute alows to set styles dynamicaly
            if (dynStyle) {
                const matches = dynStyle.match(/\{\{(.*?)\}\}/gs);
                if (matches) {
                    matches.forEach((toEval) => {
                        dynStyle = dynStyle.replace(toEval, this.evaluate.call(this, toEval.slice(2, -2)));
                    });
                    element.style.cssText = dynStyle;
                }
            }
            // 'dyn-src' attribute sets source dynamicaly
            if (dynSrc) {
                const matches = dynSrc.match(/\{\{(.*?)\}\}/gs);
                if (matches) {
                    matches.forEach((toEval) => {
                        dynSrc = dynSrc.replace(toEval, this.evaluate.call(this, toEval.slice(2, -2)));
                    });
                    element.src = dynSrc;
                }
            }
        });
    }

    _executeOnHtml(node, callback) {
        let child = node.firstChild;
        while (child) {
            callback(child);
            if (child.nodeType === Node.ELEMENT_NODE) {
                this._executeOnHtml(child, callback);
            }
            child = child.nextSibling;
        }
    }
}
