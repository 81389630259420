import { Command, CommandEnum } from './CommandModule';
import { Events } from '../events';
import { eventsManager } from '../managers/EventsManager';
import { EntityFactory, Entity, Floor, Room, roomManager, math } from '../SavaneJS';

export class EditEntityCommand  extends Command
{
    private _modifiedEntity: Entity;
    private _modifiedPosition: math.vec3;
    private _currentEntity: Entity;
    private _initialPosition: math.vec3;

    constructor(currentEntity: Entity) {
        super();
        // Clone the entity passed to the constructor (this is generally done before ending a temporary state of the entity so the copy is a copy of the temporary state)
        this._modifiedEntity = EntityFactory.cloneEntity(currentEntity, false);
        this._modifiedPosition = this._modifiedEntity.position;
        // End the entity temporary so it drops the temporary data
        currentEntity.endTemporary();
        // Original edited entity
        this._currentEntity = currentEntity;
        this._initialPosition = this._currentEntity.position;
    }

    // Command name returned from a global enum
    name(): string {
        return CommandEnum.EditEntityCommand;
    }

    // Datas that allows the command to be executed
    execDatas(): any {
        return {
            id: this._currentEntity.id,
            type: this._currentEntity.entityType,
        };
    }

    needsRefreshHull(): boolean {
        return (this.doesTreeContainFurnitureFinishes(this._currentEntity));
    }

    // Undo the current command
    undo() {
        // Call execute again, this will exchange old/new values
        this.execute();

        //Execute parent function
        super.undo();
    }

    // Execute current command (redo), this will exchange old/new values
    execute() {
        // Temporary variables
        let savedLocalMatrix = math.mat4.clone(this._currentEntity.transform.localMatrix);
        let savedWidth = (this._currentEntity as any).width;
        let savedLength = (this._currentEntity as any).length;
        let savedHeight = (this._currentEntity as any).height;
        let savedFloorHeight = this._currentEntity.floorHeight;
        let savedHoldout = this._currentEntity.holdout;
        let savedHideAtRendering = this._currentEntity.hideAtRendering;
        let savedAnchorActive;
        let savedLightOn;
        let savedLightOff;
        let savedTemperature;
        let savedLightColor;
        let savedExcludedObjectIds;
        let savedSymmetry;
        let coatingAllowed;

        // Now copy new edited data into entity
        if (!this._currentEntity.isArrangementGroupEntity()) {
            (this._currentEntity as any).width = (this._modifiedEntity as any).width;
            (this._currentEntity as any).length = (this._modifiedEntity as any).length;
            (this._currentEntity as any).height = (this._modifiedEntity as any).height;
        }
        this._currentEntity.floorHeight = this._modifiedEntity.floorHeight;
        math.mat4.copy(this._currentEntity.transform.localMatrix, this._modifiedEntity.transform.localMatrix);

        if (!this._currentEntity.isArrangementGroupEntity()) {
            (this._modifiedEntity as any).width = savedWidth;
            (this._modifiedEntity as any).length = savedLength;
            (this._modifiedEntity as any).height = savedHeight;
        }
        this._modifiedEntity.floorHeight = savedFloorHeight;
        math.mat4.copy(this._modifiedEntity.transform.localMatrix, savedLocalMatrix);

        this._currentEntity.holdout = this._modifiedEntity.holdout;
        this._modifiedEntity.holdout = savedHoldout;

        this._currentEntity.hideAtRendering = this._modifiedEntity.hideAtRendering;
        this._modifiedEntity.hideAtRendering = savedHideAtRendering;

        if ((this._currentEntity as any).isAnchorActive !== undefined) {
            savedAnchorActive = (this._currentEntity as any).isAnchorActive;
            (this._currentEntity as any).isAnchorActive = (this._modifiedEntity as any).isAnchorActive;
            (this._modifiedEntity as any).isAnchorActive = savedAnchorActive;
        }

        if ((this._currentEntity as any).lightOn !== undefined) {
            savedLightOn = (this._currentEntity as any).lightOn;
            (this._currentEntity as any).lightOn = (this._modifiedEntity as any).lightOn;
            (this._modifiedEntity as any).lightOn = savedLightOn;
        }

        if ((this._currentEntity as any).lightOff !== undefined) {
            savedLightOff = (this._currentEntity as any).lightOff;
            (this._currentEntity as any).lightOff = (this._modifiedEntity as any).lightOff;
            (this._modifiedEntity as any).lightOff = savedLightOff;
        }

        if ((this._currentEntity as any).temperature !== undefined) {
            savedTemperature = (this._currentEntity as any).temperature;
            (this._currentEntity as any).temperature = (this._modifiedEntity as any).temperature;
            (this._modifiedEntity as any).temperature = savedTemperature;
        }

        if ((this._currentEntity as any).lightColor !== undefined) {
            savedLightColor = (this._currentEntity as any).lightColor;
            (this._currentEntity as any).lightColor = (this._modifiedEntity as any).lightColor;
            (this._modifiedEntity as any).lightColor = savedLightColor;
        }

        if ((this._currentEntity as any).excludedObjectIds !== undefined) {
            savedExcludedObjectIds = (this._currentEntity as any).excludedObjectIds;
            (this._currentEntity as any).excludedObjectIds = (this._modifiedEntity as any).excludedObjectIds;
            (this._modifiedEntity as any).excludedObjectIds = savedExcludedObjectIds;
        }

        if ((this._currentEntity as any).symmetry !== undefined) {
            savedSymmetry = (this._currentEntity as any).symmetry;
            (this._currentEntity as any).symmetry = (this._modifiedEntity as any).symmetry;
            (this._modifiedEntity as any).symmetry = savedSymmetry;
        }

        if ((this._currentEntity as any).coatingAllowed !== undefined) {
            coatingAllowed = (this._currentEntity as any).coatingAllowed;
            (this._currentEntity as any).coatingAllowed = (this._modifiedEntity as any).coatingAllowed;
            (this._modifiedEntity as any).coatingAllowed = coatingAllowed;
        }

        // Only if parent exist
        if (this._currentEntity.parent) {
            // Parenting to room or floor for arrangement objects if the arrangement object inst' already parented to a group or arrangement
            if (this._currentEntity.isArrangementObjectEntity() || this._currentEntity.isArrangementGroupEntity()) {
                if (!(this._currentEntity.parent.isArrangementGroupEntity() ||
                      this._currentEntity.parent.isArrangementObjectEntity() ||
                      this._currentEntity.parent.isArrangementZoneEntity())) {
                    // Store entity positions
                    let entityPosition = math.vec3.clone(this._currentEntity.position);
                    // Get floor at that position
                    let floor = this._currentEntity.scene.getFloorAtPosition(entityPosition);

                    // But search better in case of sous-elevation
                    let floors = this._currentEntity.scene.floors;

                    for (let i = 0 ; i < floors.length ; i++) {
                        if (!floors[i] || !floor) continue;
                        if (floors[i].id === floor.id) {
                            continue;
                        }
                        let room = roomManager.getRoomAtPosition(this._currentEntity.position, floors[i]);

                        if (room) {
                            let bottom = floors[i].height + room.floorHeight;
                            let top = floors[i].height + floors[i].getFloorMaxRoomHeight();
                            if ((entityPosition[2] >= bottom) && (entityPosition[2] <= top)) {
                                floor = floors[i];
                                break;
                            }
                        }
                    }

    // Mouchard à retirer
                    if (!floor) {
                        let str = 'EditEntityCommand with no destination floor ';

                        str += 'EntityType : ' + this._currentEntity.entityType + ' ';
                        str += 'Position : ' + this._currentEntity.position[0] + ',' + this._currentEntity.position[1] + ',' + this._currentEntity.position[2] + ' ';
                        if (this._currentEntity.parent) {
                            str += 'with parent EntityType ' + this._currentEntity.parent.entityType + ' ';
                        }
                        else {
                            str += 'with no parent ';
                        }
                        str += 'its scene has ' + this._currentEntity.scene.floors.length + 'floors';
                        throw(str);
                    }
    // Mouchard à retirer

                    // Get room under the object position
                    let parent: Room | Floor = roomManager.getRoomAtPosition(this._currentEntity.position, floor);
                    // No room found attach to floor
                    if (parent === null) {
                        if (floor) {
                            parent = floor;
                        }
                        else {
                            parent = this._currentEntity.scene.currentFloor;
                        }
                    }
                    // Remove from old parent
                    this._currentEntity.parent.deleteChild(this._currentEntity.id);
                    // Attach to new parent
                    parent.addChild(this._currentEntity);
                    // Reposition entity
                    this._currentEntity.position = entityPosition;
                }
            }
        }

        let scale = this._currentEntity.transform.globalScale;
        this._currentEntity.inverseScaleOnHandles([1. / scale[0], 1. / scale[1], 1. / scale[2]]);

        // Mouchard à retirer
        if (!this._modifiedEntity.position) {
            let str = 'EditEntityCommand with undefined destination position ';

            str += 'EntityType : ' + this._modifiedEntity.entityType + ' ';
            str += 'OriginalObject entityType : ' + this._currentEntity.entityType + ' ';
            throw(str);
        }
        // Mouchard à retirer

        // Refresh arrangement group upwards the hierarchy if needed
        let parent = this._currentEntity.parent;
        // While parent is an arrangement group, decorated or objact
        while (parent && (parent.isArrangementGroupEntity() ||
            parent.isArrangementObjectEntity() ||
            parent.isArrangementZoneEntity())) {
            // Recenter parent
            parent.recenter();

            // Redraw Node
            eventsManager.instance.dispatch(Events.REDRAW_GRAPHICAL_ENTITY,
                {
                    entity: parent
                });

            // And go upper in the hierarchy
            parent = parent.parent;
        }
        // Re-inject original position after the recenter of the group into the modified entity
        this._modifiedEntity.position = this._initialPosition;
        this._currentEntity.position = this._modifiedPosition;

        let savedPosition = this._initialPosition;
        this._initialPosition = this._modifiedPosition;
        this._modifiedPosition = savedPosition;

        // Execute parent function
        super.execute();

        // Select object at the end
        eventsManager.instance.dispatch(Events.CHANGE_EDITOR_SELECTION,
            {
                selection: [this._currentEntity],
                keepSelected: false,
                showTulip: false
            });
    }
};
