import * as THREE from 'three';

export class CubeMapGenerator {

    static fromEquirectangularTexture( renderer, texture, rotation, size = 1) {
        let cubeTexture = new THREE.WebGLCubeRenderTarget(size);

        cubeTexture.texture.type = texture.type;
		cubeTexture.texture.colorSpace = texture.colorSpace;

		cubeTexture.texture.generateMipmaps = texture.generateMipmaps;
		cubeTexture.texture.minFilter = texture.minFilter;
		cubeTexture.texture.magFilter = texture.magFilter;

		const shader = {

			uniforms: {
				tEquirect: { value: null },
				uRotation: { value: new THREE.Matrix4() }
			},

			vertexShader: /* glsl */`
				uniform mat4 uRotation;
				varying vec3 vWorldDirection;
				vec3 transformDirection( in vec3 dir, in mat4 matrix ) {
                    vec3 d = ( matrix * vec4( dir, 0.0 ) ).xyz;
					return (uRotation * vec4( normalize( vec3(d.y, d.z, d.x) ), 1 )).xyz;
				}
				void main() {
					vWorldDirection = transformDirection( position, modelMatrix );
					#include <begin_vertex>
					#include <project_vertex>
				}
			`,

			fragmentShader: /* glsl */`
				uniform sampler2D tEquirect;
				varying vec3 vWorldDirection;
				#include <common>
				void main() {
					vec3 direction = normalize( vWorldDirection );
					vec2 sampleUV = equirectUv( direction );
					gl_FragColor = texture2D( tEquirect, sampleUV );
				}
			`
		};

		const geometry = new THREE.BoxGeometry( 5, 5, 5 );

		const material = new THREE.ShaderMaterial( {

			name: 'CubemapFromEquirect',

			uniforms: THREE.UniformsUtils.clone( shader.uniforms ),
			vertexShader: shader.vertexShader,
			fragmentShader: shader.fragmentShader,
			side: THREE.BackSide,
			blending: THREE.NoBlending

		} );

		material.uniforms.tEquirect.value = texture;
		material.uniforms.uRotation.value = rotation;

		const mesh = new THREE.Mesh( geometry, material );

		const currentMinFilter = texture.minFilter;

		// Avoid blurred poles
		if ( texture.minFilter === THREE.LinearMipmapLinearFilter ) texture.minFilter = THREE.LinearFilter;

		const scene = new THREE.Scene();
		const camera = new THREE.CubeCamera( 1, 10, cubeTexture );
		scene.add(camera);
		scene.add(mesh);
		camera.update( renderer, scene );

		texture.minFilter = currentMinFilter;

		scene.remove(camera);
		mesh.geometry.dispose();
		mesh.material.dispose();

		return cubeTexture;

    }

}
