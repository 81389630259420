import { ComponentConstants, Area, Floor, Wall, Room, Joinery, GeometryPrimitive, TechnicalElement, WorkTop, SceneConstants, SavaneConstants, math, Transform, SavaneMath, Segment, wallManager, roomManager, joineryManager, arrangementManager, ArrangementGroup, ArrangementObject } from "../SavaneJS";

/**
 * PlanManager is a singleton managing all plan object (rooms, walls and joineries) of the scene
 *
 * @constructor
 */
class PlanManager {
    constructor() {
    }

    /**
     * get component area in floor
     *
     * @param {*} position
     * @returns {*}
     */
    getAreaAtPosition(position: math.vec3, componentTypes: Array<ComponentConstants.ComponentType>, includeWorktop: boolean, floor: Floor) : Area {
        let areas = [];

        if (includeWorktop) {
            let worktop = this.getWorktopAtPosition(position, floor, false);
            if (worktop) {
                let area = worktop.getComponent(ComponentConstants.ComponentType.Area);
                if (area) {
                    areas.push(area);
                }
            }
        }
        for (let i = 0; i < componentTypes.length; ++i) {
            areas = areas.concat(floor.getComponents(componentTypes[i]));
        }

        for (let i = 0; i < areas.length; ++i) {
            if (areas[i].isInArea(position)) {
                return areas[i];
            }
        }
        return null;
    }

    /**
     * get component area with chip in floor and room
     *
     * @param {*} chipType
     * @returns {*}
     */
    getAreaWithChip(chipType: ComponentConstants.Functionalities, floor: Floor, room: Room) : Area |null {
        // Get all areas from floor
        let areas = floor.getComponents(ComponentConstants.ComponentType.Area) as Array<Area>;
        // Get chips from room
        let chips = room.secondaryFunctionalities;

        // Parse all areas
        for (let i = 0; i < areas.length; i++) {
            // And all chips
            for (let j = 0; j < chips.length; j++) {
                // If the chip to test has the requested type
                if (chips[j].functionalityId === chipType) {
                    // Get its position
                    let position = chips[j].entity.position;

                    // And if the chip is in the area
                    if (areas[i].isInArea(position)) {
                        // Return the area
                        return areas[i];
                    }
                }
            }
        }
        return null;
    }

    /**
     * get component area in floor and room
     *
     * @param {*} floor
     * @param {*} room
     * @returns {*}
     */
    getAreasInRoom(floor: Floor, room: Room) : Array<Area> {
        // Get all areas from floor
        let areas = floor.getComponents(ComponentConstants.ComponentType.Area) as Array<Area>;
        // Array to return
        let areasToReturn = new Array<Area>();

        // Parse all areas
        for (let i = 0; i < areas.length; i++) {
            // Get current area to test
            let areaToTest = areas[i];
            let j;

            // Parse all vertices of the area to test
            for (j = 0; j < areaToTest.vertices.length; j++) {
                // If one if the vertices isn't in the room, break the loop
                if (roomManager.getRoomAtPosition(areaToTest.vertices[j], floor) !== room) {
                    break;
                }
            }

            // When arrive here if j has reached vertices length then all area vertices are in the room so add the area to the returned array
            if (j === areaToTest.vertices.length) {
                areasToReturn.push(areaToTest);
            }
        }

        // Return array with all areas
        return areasToReturn;
    }

    /**
     * get worktop at position in floor
     *
     * @param {*} position
     * @returns {*}
     */
    getWorktopAtPosition(position: math.vec3, floor: Floor, reverse: boolean) : WorkTop | null {
        //let areas = null;
        let list = floor.getChildren([SceneConstants.EntityType.WorkTop], [SceneConstants.EntityType.Room, SceneConstants.EntityType.ArrangementGroup]) as Array<WorkTop | ArrangementGroup>;

        let candidates = [];
        for (let i = 0; i < list.length; i++) {
            if (list[i].contains(position, undefined, undefined)) {
                candidates.push(list[i]);
            }
        }

        if (candidates.length === 0) {
            return null;
        }

        if (reverse) {
            candidates.sort(function (a, b) {
                return b.maxHeight - a.maxHeight;
            });
        } else {
            candidates.sort(function (a, b) {
                return a.maxHeight - b.maxHeight;
            });
        }

        return candidates[candidates.length - 1];
    }

    /**
     * Getter for the position magnet on plan object (non temporary object only)
     *
     * @param {*} position
     * @param {Number} cornerPrecision
     * @param  {Wall} wall
     * @returns {*}
     */
    getMagnetPositionEntityEdit(position: math.vec3, cornerPrecision: number, wall: Wall) : math.vec3 {
        let res = math.vec3.create();
        math.vec3.copy(res, position);
        //Convert position to node space
        let invertMat = math.mat4.create();
        let wallMatrix = wall.globalMatrix;
        math.mat4.invert(invertMat, wallMatrix);
        let localNormal = math.vec3.create();
        math.vec3.transformMat4(localNormal, res, invertMat);
        localNormal[1] = 0;
        math.vec3.transformMat4(res, localNormal, wallMatrix);
        //Retrieve boundingboxcut
        let bbcut = wall.boundingBoxCut;
        let beginSnap = null;
        if (math.vec3.dist(wall.end, bbcut[0]) > math.vec3.dist(wall.end, bbcut[1])) {
            beginSnap = bbcut[1];
        } else {
            beginSnap = bbcut[0];
        }
        let endSnap = null;
        if (math.vec3.dist(wall.begin, bbcut[2]) > math.vec3.dist(wall.begin, bbcut[3])) {
            endSnap = bbcut[3];
        } else {
            endSnap = bbcut[2];
        }
        let distA = math.vec3.dist(res, endSnap);
        let distB = math.vec3.dist(res, beginSnap);
        if (distA > distB) {
            if (distB < cornerPrecision) {
                math.vec3.copy(res, beginSnap);
                math.vec3.transformMat4(localNormal, res, invertMat);
                localNormal[1] = 0;
                math.vec3.transformMat4(res, localNormal, wallMatrix);
                return res;
            }
        } else {
            if (distA < cornerPrecision) {
                math.vec3.copy(res, endSnap);
                math.vec3.transformMat4(localNormal, res, invertMat);
                localNormal[1] = 0;
                math.vec3.transformMat4(res, localNormal, wallMatrix);
                return res;
            }
        }
        return res;
    }

    /**
     * Getter for the position magnet on plan object inside a room
     *
     * @param {*} position
     * @param {Number} cornerPrecision
     * @param  {Number} precision
     * @returns {*}
     */
    getMagnetPositionInsideRoom(position: math.vec3, cornerPrecision: number, precision: number, floor: Floor) : math.vec3 {
        let room = roomManager.getRoomAtPosition(position, floor);
        let res = math.vec3.clone(position);
        let i, j;
        if (room !== null) {
            //point magnet
            let innerRooms = room.getInnerRoomList(undefined);
            let points = room.getInnerPointsList(undefined);

            for (i = 0; i < points.length; i++) {
                if (math.vec3.dist(points[i], position) < cornerPrecision) {
                    math.vec3.copy(res, points[i]);
                    return res;
                }
            }

            for (i = 0; i < innerRooms.length; i++) {
                points = innerRooms[i].getOutterPointList();
                for (j = 0; j < points.length; j++) {
                    if (math.vec3.dist(points[j], position) < cornerPrecision) {
                        math.vec3.copy(res, points[j]);
                        return res;
                    }
                }
            }

            //line magnet
            points = room.getInnerPointsList(undefined);
            let segment = new Segment(math.vec3.create(), math.vec3.create());
            for (i = 0; i < points.length; i++) {
                segment.begin = points[i];
                segment.end = points[(i + 1) % points.length];

                if (segment.isPointOnSegment(position, precision)) {
                    let projection = segment.orthogonalProjection(position);
                    if (projection) {
                        math.vec3.copy(res, projection);
                        return res;
                    }
                }
            }

            for (i = 0; i < innerRooms.length; i++) {
                points = innerRooms[i].getOutterPointList();
                for (j = 0; j < points.length; j++) {
                    segment.begin = points[j];
                    segment.end = points[(j + 1) % points.length];
                    if (segment.isPointOnSegment(position, precision)) {
                        let projection = segment.orthogonalProjection(position);
                        if (projection) {
                            math.vec3.copy(res, projection);
                            return res;
                        }
                    }
                }
            }
        }
        return res;
    }

    /**
     * Getter for the position magnet on plan object (non temporary object only)
     *
     * @param {*} position
     * @param {Number} precision
     * @param  {Number} cornerPrecision
     * @returns {*}
     */
    getMagnetPositionOnNonTemporaryEntity(position: math.vec3, precision: number, cornerPrecision: number, floor: Floor) : math.vec3 {
        let res = math.vec3.create();
        let walls = wallManager.getWallsAtCorner(position, floor, cornerPrecision);
        if (walls !== null) {
            for (let i = walls.length - 1; i >= 0; i--) {
                if (walls[i].isTemporary()) {
                    walls.splice(i, 1);
                }
            }
            if (walls.length !== 0) {
                if (math.vec3.dist(position, walls[0].begin) > math.vec3.dist(position, walls[0].end)) {
                    math.vec3.copy(res, walls[0].end);
                    return res;
                } else {
                    math.vec3.copy(res, walls[0].begin);
                    return res;
                }
            }
        }

        walls = wallManager.getWallsAtPosition(position, floor, precision, -1);
        //This operation is only called on currentFloor ? We may need to add a parameter for the designated floor
        if (walls !== null) {
            for (let i = walls.length - 1; i >= 0; i--) {
                if (walls[i].isTemporary()) {
                    walls.splice(i, 1);
                }
            }

            if (walls.length !== 0) {
                let minDistToWall = SavaneMath.distanceToLine(position, walls[0].begin, walls[0].end);
                let wall = walls[0];
                for (let i = 0; i < walls.length; i++) {
                    if (minDistToWall > SavaneMath.distanceToLine(position, walls[i].begin, walls[i].end)) {
                        minDistToWall = SavaneMath.distanceToLine(position, walls[i].begin, walls[i].end);
                        wall = walls[i];
                    }
                }

                let vec = wall.wallDirection;
                let seg = new Segment(wall.begin, wall.end);

                seg.begin[0] = seg.begin[0] + vec[1] * -wall.shiftOffset;
                seg.begin[1] = seg.begin[1] + vec[0] * wall.shiftOffset;
                seg.end[0] = seg.end[0] + vec[1] * -wall.shiftOffset;
                seg.end[1] = seg.end[1] + vec[0] * wall.shiftOffset;

                math.vec3.copy(res, seg.orthogonalProjection(position));
                return res;
            }
        }
        math.vec3.copy(res, position);
        return res;
    }

    /**
     * Retrieve the position after applying a snap on arrangement points concerned by worktop
     * Mainly kitchenFurnitureBottom
     **/
    getMagnetPositionWorktop(position: math.vec3, floor: Floor, magnetDistance: number) : math.vec3 {
        //Find list of closes arrangement
        let fullList = floor.getChildren([SceneConstants.EntityType.ArrangementObject]) as Array<ArrangementObject>;
        //Extract list of arrangement that are linked to worktop
        fullList = fullList.filter((item) => item.isWorktopBottomArrangement() || item.objectType === SceneConstants.ArrangementType.kitchenFurnitureColumn || (item.objectType === SceneConstants.ArrangementType.kitchenFurnitureTop && item.floorHeight < SavaneConstants.minFloorHeightBottom));
        let snapPoint = null;
        for (let i = 0; i < fullList.length; i++) {
            let item = fullList[i];
            //Now check snap with points
            let bbox = item.worktopBox;
            //Add margin in front of bbox
            for (let j = 0; j < bbox.length; j++) {
                let distToPoint = math.vec3.distance(position, bbox[j]);
                //Check dist with point
                if (distToPoint < magnetDistance) {
                    //Save dist and point
                    snapPoint = bbox[j];
                    snapPoint[2] = item.maxHeight;
                    break;
                }
            }
        }
        if (snapPoint === null) {
            let walls = wallManager.getWallsAtPosition(position, floor, magnetDistance, -1);
            let wall = walls !== null ? walls[0] : null;
            if (wall !== null) {
                //Snap the snapped point to wall (snapcetion :o)
                let onWallPos = math.vec3.create();
                //Snap to wall
                math.vec3.transformMat4(onWallPos, position, wall.transform.invertedGlobalMatrix);
                if (onWallPos[1] - wall.shiftOffset >= 0) {
                    onWallPos[1] = wall.thickness * 0.5 + wall.shiftOffset;
                } else {
                    onWallPos[1] = -wall.thickness * 0.5 + wall.shiftOffset;
                }
                math.vec3.transformMat4(onWallPos, onWallPos, wall.transform.globalMatrix);
                return onWallPos;
            }
            return position;
        } else {
            return snapPoint;
        }
    }

    snapArrangement(arrangement: (ArrangementObject | ArrangementGroup), floor: Floor) : void {
        if (arrangement.temporary !== null) {
            arrangement.temporary.recomputeValidity = true;
        }
        let position = arrangement.position;
        // Considet the object is at 0 height to be comparable with walls (which have a Z = 0 too)
        position[2] = 0;
        // Cap max width length to half a standard wall thichness
        let maxWidthLength = Math.max(arrangement.width, arrangement.length);
        if (maxWidthLength < 170) {
            maxWidthLength = 170;
        }
        //Retrieve wall list close to arrangement position
        let walls = wallManager.getWallsAtPositionWithoutCorner(position, floor, maxWidthLength, -1);
        if (walls) {
            walls = walls.filter(function (wall) {
                let projection = wall.segment.orthogonalProjection(position);
                return wall.segment.isPointOnSegment(projection, 1);
            });
        }
        if (walls !== null && walls.length !== 0) {
            //List of wall isn't empty, sort from closest to farest
            walls.sort(function (a, b) {
                return SavaneMath.distanceToLine(position, a.begin, a.end) - SavaneMath.distanceToLine(position, b.begin, b.end);
            });
            let firstWall = walls[0];
            let secondWall = null;
            for (let i = 1; i < walls.length; i++) {
                //Find a wall orthogonal from closest in list
                if (Segment.areOrthogonal(firstWall.segment, walls[i].segment, 0.01)) {
                    secondWall = walls[i];
                }
            }
            this._snapTopWall(arrangement, firstWall, true);
            if (secondWall !== null) {
                this._snapTopWall(arrangement, secondWall, false);
            }
        }

        //Snap to arrangement
        this.snapToNeighboors(arrangement, floor);
    }
    /**
     *
     *
     * @param arrangement {ArrangementObject} Object to snap
     * @param wall {Wall} Wall to snap to
     * @param primary {Boolean} Is the primary snap for that arrangement
     *
     **/
    _snapTopWall(arrangement: (ArrangementObject | ArrangementGroup | TechnicalElement | GeometryPrimitive), wall: Wall, primary: boolean) : void {
        let box = arrangement.boundingBox;
        //Convert box global to wall
        let wallInvertedMatrix = wall.transform.invertedGlobalMatrix;
        let localbox = [];
        for (let i = 0; i < 4; i++) {
            localbox[i] = math.vec3.create();
            math.vec3.transformMat4(localbox[i], box[i], wallInvertedMatrix);
        }
        //Find sign using box center
        let localPosition = math.vec3.create();
        math.vec3.transformMat4(localPosition, arrangement.position, wallInvertedMatrix);
        //Find the closest point from wall line (y value)
        let closestIndex = 0;
        for (let i = 0; i < 4; i++) {
            if (localbox[i][1] < localbox[closestIndex][1]) {
                closestIndex = i;
            }
        }

        let snapBackToWall = false;
        if (arrangement.isSnappableBottomArrangement() || arrangement.isSnappableTopArrangement() || arrangement.isSnappableColumnArrangement()) {
            snapBackToWall = true;
        } else {
            if (arrangement.isTechnicalElementEntity() || arrangement.isArrangementObjectEntity() || arrangement.isArrangementGroupEntity() || arrangement.isGeometryPrimitiveEntity()) {
                snapBackToWall = true;
            }
        }

        //Only apply global constraint for the primary snap
        if (primary && snapBackToWall) {
            //Always snap back
            if (localPosition[1] >= 0) {
                closestIndex = 3;
            } else {
                closestIndex = 1;
            }
        } else {
            //Find wich of two edge should be aligned with the wall
            let dir1 = math.vec3.create();
            let index1 = closestIndex > 0 ? closestIndex - 1 : 3;
            math.vec3.subtract(dir1, localbox[index1], localbox[closestIndex]);
            math.vec3.normalize(dir1, dir1);
            let dir2 = math.vec3.create();
            let index2 = (closestIndex + 1) % 4;
            math.vec3.subtract(dir2, localbox[index2], localbox[closestIndex]);
            math.vec3.normalize(dir2, dir2);
            if (Math.abs(dir1[1]) < Math.abs(dir2[1])) {
                closestIndex = index1;
            }
        }

        //Generate local matrix for arrangement
        let localMatrix = math.mat4.create();
        let sideDim; //Store dimension along walls
        switch (closestIndex) {
            case 0:
                sideDim = arrangement.length;
                math.mat4.rotateZ(localMatrix, localMatrix, (3.0 * Math.PI) / 2.0);
                break;
            case 1:
                sideDim = arrangement.width;
                math.mat4.rotateZ(localMatrix, localMatrix, localPosition[1] >= 0 ? 0 : Math.PI); //Prevent front to be against wall
                break;
            case 2:
                sideDim = arrangement.length;
                math.mat4.rotateZ(localMatrix, localMatrix, Math.PI / 2.0);
                break;
            case 3:
                sideDim = arrangement.width;
                math.mat4.rotateZ(localMatrix, localMatrix, localPosition[1] >= 0 ? 0 : Math.PI); //Prevent front to be against wall
                break;
        }
        if (arrangement.isGeometryPrimitiveEntity() && (arrangement as GeometryPrimitive).primitiveType === SceneConstants.GeometryPrimitiveType.halfarc) {
            math.mat4.rotateZ(localMatrix, localMatrix, -Math.PI / 2);
        }
        localMatrix[12] = localPosition[0];
        // Calculate snapped dist to wall and increase wall thickness by 1mm to avoid round mistakes
        if (localPosition[1] >= 0) {
            localMatrix[13] = wall.shiftOffset + (wall.thickness + 1 + sideDim) * 0.5;
        } else {
            localMatrix[13] = wall.shiftOffset - (wall.thickness + 1 + sideDim) * 0.5;
        }
        localMatrix[14] = localPosition[2];
        //Only apply if the calculate dist is bigger than the previous position(absolute)
        //FIXME this create a problem if the object is turned more than 90°, to fix it consult the rotation offset of the transformation and handle the diff
        if (Math.abs(localPosition[1]) < Math.abs(localMatrix[13])) {
            //Set global matrix to arrangement
            math.mat4.rotateX(localMatrix, localMatrix, arrangement.transform.localXRotation);
            math.mat4.rotateY(localMatrix, localMatrix, arrangement.transform.localYRotation);
            let globalMatrix = math.mat4.create();
            math.mat4.multiply(globalMatrix, wall.transform.globalMatrix, localMatrix);
            let localScale = arrangement.transform.localScale;
            if (arrangement.parent !== null) {
                math.mat4.multiply(arrangement.transform.localMatrix, arrangement.parent.transform.invertedGlobalMatrix, globalMatrix);
            } else {
                math.mat4.copy(arrangement.transform.localMatrix, globalMatrix);
            }
            arrangement.transform.localScale = localScale;
        }
    }

    setArrangementAngle(arrangement: (ArrangementObject | ArrangementGroup | TechnicalElement | GeometryPrimitive), newAngle: number): void {
        let arrangementToAssign = arrangement;
        while (arrangementToAssign.parent && arrangementToAssign.parent.isArrangementGroupEntity()) {
            arrangementToAssign = (arrangementToAssign.parent as ArrangementGroup);
        }
        let deltaAngle = newAngle;
        deltaAngle -= arrangement.angle;
        arrangementToAssign.angle += deltaAngle;
    }

    setArrangementPosition(arrangement: (ArrangementObject | ArrangementGroup | TechnicalElement | GeometryPrimitive), newPosition: math.vec3) : void {
        let arrangementToAssign = arrangement;
        while (arrangementToAssign.parent && arrangementToAssign.parent.isArrangementGroupEntity()) {
            arrangementToAssign = (arrangementToAssign.parent as ArrangementGroup);
        }
        let deltaPosition = math.vec3.create();
        math.vec3.subtract(deltaPosition, newPosition, arrangement.position);
        newPosition = arrangementToAssign.position;
        math.vec3.add(newPosition, newPosition, deltaPosition);
        arrangementToAssign.position = newPosition;        
    }

    /**
     *
     * Snap arrangement to nearby seabling
     * TODO This function only snap kitchen element by now may be improve with more abstract rules to handle all object types     *
     * @param arrangement
     * @param floor
     */
    snapToNeighboors(arrangement: (ArrangementObject | ArrangementGroup | TechnicalElement | GeometryPrimitive), floor: Floor) : void {
        if (arrangement.isArrangementGroupEntity()) {
            for (let i = 0; i < arrangement.children.length; i++) {
                this.snapToNeighboors((arrangement.children[i] as ArrangementGroup), floor);
            }
            return;
        }

        //Find list of closes arrangement
        let fullList = floor.getChildren([SceneConstants.EntityType.ArrangementObject]);
        let floorArrangements = [];
        for (let i = 0; i < fullList.length; i++) {
            let arr = fullList[i];
            //don't add himself
            if (arr.id !== arrangement.id) {
                let canBeAdded = false;
                //The current arrangement objectTtype define rules to select object that will snap together
                if (arrangement.isSnappableBottomArrangement()) {
                    canBeAdded = arr.isSnappableBottomArrangement() || arr.isSnappableColumnArrangement() || (arr.isSnappableTopArrangement() && arr.floorHeight < SavaneConstants.minFloorHeightBottom); //FIXME custom rule for topArr set on floor
                } else {
                    if (arrangement.isSnappableTopArrangement()) {
                        if (arrangement.floorHeight < SavaneConstants.minFloorHeightBottom) {
                            canBeAdded = arr.isSnappableBottomArrangement() || arr.isSnappableColumnArrangement() || (arr.isSnappableTopArrangement() && arr.floorHeight < SavaneConstants.minFloorHeightBottom); //FIXME custom rule for topArr set on floor
                        } else {
                            canBeAdded = arr.isSnappableTopArrangement() || arr.isSnappableColumnArrangement();
                        }
                    } else {
                        if (arrangement.isSnappableColumnArrangement()) {
                            canBeAdded = arr.isSnappableBottomArrangement() || arr.isSnappableTopArrangement() || arr.isSnappableColumnArrangement();
                        }
                    }
                }

                if (canBeAdded) {
                    //Add to restricted list
                    floorArrangements.push(fullList[i]);
                }
            }
        }

        let position = arrangement.position;
        //No arrangement to snap with
        if (floorArrangements.length < 1) {
            return;
        }
        //Sort by closest
        floorArrangements.sort(function (a, b) {
            //The two first test will push arrangement at the end of the list
            let pA = a.position;
            let pB = b.position;
            let distA = (pA[0] - position[0]) * (pA[0] - position[0]) + (pA[1] - position[1]) * (pA[1] - position[1]);
            let distB = (pB[0] - position[0]) * (pB[0] - position[0]) + (pB[1] - position[1]) * (pB[1] - position[1]);
            return distA - distB;
        });

        // Copy object transform
        let floorArrangementTransform = new Transform(null);
        floorArrangementTransform.clone(floorArrangements[0].transform);
        // Cancel scale
        let scale1 = math.vec3.create();
        math.vec3.set(scale1, 1, 1, 1);
        floorArrangementTransform.localScale = scale1;

        let toWorld = floorArrangementTransform.globalMatrix;

        //Calculate distance to closest (2D)
        //let closePosition = floorArrangements[0].position;
        let relativePosition = math.vec3.create();
        math.vec3.transformMat4(relativePosition, position, floorArrangementTransform.invertedGlobalMatrix);

        let xDist = relativePosition[0];
        let yDist = relativePosition[1];
        let closeDist = Math.sqrt(xDist * xDist + yDist * yDist);
        //Norm dist
        xDist = xDist / closeDist;
        yDist = yDist / closeDist;

        let isSide = Math.abs(xDist) > Math.abs(yDist);
        let isTwoSides = false;
        if (floorArrangements[0].objectTypeConfig !== undefined) {
            isTwoSides = floorArrangements[0].objectTypeConfig.angleDirection === "twoSided" || floorArrangements[0].objectTypeConfig.angleDirection === "twoSidedBeveled";
        }
        if (yDist < 0 || isSide || isTwoSides) {
            let aimedDist = !isSide ? floorArrangements[0].width * 0.5 + arrangement.width * 0.5 : floorArrangements[0].length * 0.5 + arrangement.length * 0.5; //FIXME this test is only working for the kitchen case (all item look at the same direction)

            if (isTwoSides && !isSide && yDist > 0) {
                aimedDist = floorArrangements[0].length * 0.5 + arrangement.length * 0.5; //FIXME this test is only working for the kitchen case (all item look at the same direction)
            }

            if (!(arrangement as ArrangementObject).collides(floorArrangements[0]) || arrangement.floorHeight > floorArrangements[0].floorHeight + floorArrangements[0].height || floorArrangements[0].floorHeight > arrangement.floorHeight + arrangement.height) {
                //Arrangements are too far
                return;
            }

            let newRelativePosition = math.vec3.create();
            let newPosition = math.vec3.create();
            let angle = floorArrangements[0].angle;

            if (isSide) {
                //Items are side by side
                // ICI
                //arrangement.setRotationZ(angle);
                this.setArrangementAngle(arrangement, angle);
                //Calculate decal to fit back of snapped item
                let height = 0;
                let setObjectHeightPosition = false;
                if (arrangement.isSnappableTopArrangement() && arrangement.floorHeight >= SavaneConstants.minFloorHeightBottom) {
                    arrangement.isAnchorActive = false;
                    height = -(arrangement.height - floorArrangements[0].height) * 0.5;
                    setObjectHeightPosition = true;
                }
                if (xDist > 0) {
                    math.vec3.set(newRelativePosition, aimedDist, (arrangement.width - floorArrangements[0].width) * 0.5, height);
                    // If corner kitchen furniture
                    if (isTwoSides) {
                        let aimedDist = isSide ? floorArrangements[0].width * 0.5 + arrangement.width * 0.5 : floorArrangements[0].length * 0.5 + arrangement.length * 0.5; //FIXME this test is only working for the kitchen case (all item look at the same direction)
                        math.vec3.set(newRelativePosition, aimedDist, (arrangement.width - floorArrangements[0].width) * 0.5, height);
                        // ICI
                        //arrangement.setRotationZ(angle - Math.PI / 2);
                        this.setArrangementAngle(arrangement, angle - Math.PI / 2);
                    }
                } else {
                    math.vec3.set(newRelativePosition, -aimedDist, (arrangement.width - floorArrangements[0].width) * 0.5, height);

                    if ((arrangement as ArrangementObject).objectTypeConfig && ((arrangement as ArrangementObject).objectTypeConfig.angleDirection === "twoSided" || (arrangement as ArrangementObject).objectTypeConfig.angleDirection === "twoSidedBeveled")) {
                        // ICI
                        //arrangement.setRotationZ(angle - Math.PI / 2);
                        this.setArrangementAngle(arrangement, angle - Math.PI / 2);
                    }
                }
                math.vec3.transformMat4(newPosition, newRelativePosition, toWorld);
                //Prevent item to be moved vertically
                if (!setObjectHeightPosition) {
                    newPosition[2] = arrangement.position[2];
                }

                this.setArrangementPosition(arrangement, newPosition);
            } else if (yDist < 0) {
                //Items are back to back
                //Invert angle
                // ICI
                //arrangement.setRotationZ(Math.PI + angle);
                this.setArrangementAngle(arrangement, Math.PI + angle);
                //Calculate decal to fit back of snapped item
                math.vec3.set(newRelativePosition, 0, -aimedDist, 0);
                math.vec3.transformMat4(newPosition, newRelativePosition, toWorld);
                //Prevent item to be moved veritcally
                newPosition[2] = arrangement.position[2];

                this.setArrangementPosition(arrangement, newPosition);
            } else if (isTwoSides) {
                //Items are back to back
                //Invert angle
                // ICI
                //arrangement.setRotationZ(Math.PI / 2 + angle);
                this.setArrangementAngle(arrangement, Math.PI / 2 + angle);
                //Calculate decal to fit back of snapped item
                math.vec3.set(newRelativePosition, -(arrangement.width - floorArrangements[0].length) * 0.5, aimedDist, 0);
                math.vec3.transformMat4(newPosition, newRelativePosition, toWorld);
                //Prevent item to be moved veritcally
                newPosition[2] = arrangement.position[2];

                this.setArrangementPosition(arrangement, newPosition);
            }
        }
    }

    /**
     * Getter for the position magnet on guide (align with point of the plan) return the array of aligned point
     * first is the point aligned vertically second one is the point aligned horizontally
     *
     * @param {*} position
     * @param {Number} precision
     * @param {*} ignoredPoint
     * @returns {*}
     */
    getAlignement(position: math.vec3, precision: number, ignoredPoint: Array<math.vec3>, floor: Floor) : Array<math.vec3> {
        let res = [null, null];
        let pos = math.vec3.clone(position);
        let walls = floor.walls;
        for (let i = 0; i < walls.length; i++) {
            //Check if the begin point is in the ignored points list
            let toIgnore = false;
            if (ignoredPoint !== null && ignoredPoint !== undefined) {
                for (let j = 0; j < ignoredPoint.length; j++) {
                    if (math.vec3.dist(walls[i].begin, ignoredPoint[j]) < precision) {
                        toIgnore = true;
                        break;
                    }
                }
            }
            if (!toIgnore) {
                //horizontal line passing at wall.Begin equation is y+c=0
                let c1 = -walls[i].begin[1];
                let distPosToLine1 = Math.abs(pos[1] + c1);
                if (distPosToLine1 < precision) {
                    res[1] = walls[i].begin;
                }

                //Vertical line passing at points[i] equation is x+c=0
                c1 = -walls[i].begin[0];
                distPosToLine1 = Math.abs(pos[0] + c1);
                if (distPosToLine1 < precision) {
                    res[0] = walls[i].begin;
                }
            }
            //Check if the end point is in the ignored points list
            toIgnore = false;
            if (ignoredPoint !== null && ignoredPoint !== undefined) {
                for (let j = 0; j < ignoredPoint.length; j++) {
                    if (math.vec3.dist(walls[i].end, ignoredPoint[j]) < precision) {
                        toIgnore = true;
                        break;
                    }
                }
            }
            if (!toIgnore) {
                //horizontal line passing at wall.Begin equation is y+c=0
                let c1 = -walls[i].end[1];
                let distPosToLine1 = Math.abs(pos[1] + c1);
                if (distPosToLine1 < precision) {
                    res[1] = walls[i].end;
                }

                //Vertical line passing at points[i] equation is x+c=0
                c1 = -walls[i].end[0];
                distPosToLine1 = Math.abs(pos[0] + c1);
                if (distPosToLine1 < precision) {
                    res[0] = walls[i].end;
                }
            }
        }
        return res;
    }

    /**
     * Get floor under the designated floor
     *
     * @param floor
     * @returns {*}
     */
    getUnderFloor(floor: Floor) : Floor | null {
        let scene = floor.scene;
        let underFloorHeight = -1000000;
        let floorIndex = -1;
        let floors;

        if (scene !== null) {
            floors = scene.floors;

            for (let i = 0; i < floors.length; i++) {
                if (floors[i].height < floor.height) {
                    if (floors[i].height > underFloorHeight) {
                        underFloorHeight = floors[i].height;
                        floorIndex = i;
                    }
                }
            }
        }

        if (floorIndex === -1) {
            return null;
        } else {
            return floors[floorIndex];
        }
    }

    /**
     * Retrieve entities at position, using filters if setted to reduce research field
     * If no filters are provided, the function will return all possible entities
     *
     * @param {math.vec3} position
     * @param {*} filters JSON struct (See default struct for availables values)
     * @returns {*}
     */
    getEntitiesAtPosition(position: math.vec3, filters: Array<string>, floor: Floor) : {
        walls: Array<Wall>,
        rooms: Array<Room>,
        joineries: Array<Joinery>,
        technicalElements: Array<TechnicalElement>,
        geometryPrimitives: Array<GeometryPrimitive>
    } {
        //Return struct
        let returnStruct;
        if (filters === undefined) {
            //Default return struct will fill all fields
            returnStruct = {
                walls: new Array<Wall>(),
                rooms: new Array<Room>(),
                joineries: new Array<Joinery>(),
                technicalElements: new Array<TechnicalElement>(),
                geometryPrimitives: new Array<GeometryPrimitive>()
            };
        } else {
            //Create an array for each filter row
            returnStruct = {};
            for (let prop in filters) {
                if (prop) {
                    returnStruct[prop] = [];
                }
            }
        }
        //For each array in the returnStruct use appropriate method to retrieve entities
        for (let prop in returnStruct) {
            if (prop) {
                switch (prop) {
                    case "walls":
                        //TODO How to handle different methods for getting walls ?
                        let walls = wallManager.getWallsAtPositionWithoutCorner(position, floor, SavaneConstants.PositionTolerance, -1);
                        if (walls !== null) {
                            returnStruct[prop] = walls;
                        }
                        break;
                    case "rooms":
                        let room = roomManager.getRoomAtPosition(position, floor);
                        if (room !== null) {
                            returnStruct[prop] = [room];
                        }
                        break;
                    case "joineries":
                        let joinery = joineryManager.getJoineryAtPosition(position, SavaneConstants.PositionTolerance, floor);
                        if (joinery !== null) {
                            returnStruct[prop] = [joinery];
                        }
                        break;
                    case "technicalElements":
                        let technicalElement = arrangementManager.getTechnicalElementAtPosition(position, floor);
                        if (technicalElement !== null) {
                            returnStruct[prop] = [technicalElement];
                        }
                        break;
                    case "geometryPrimitives":
                        let geometryPrimitive = arrangementManager.getGeometryPrimitiveAtPosition(position, floor);
                        if (geometryPrimitive !== null) {
                            returnStruct[prop] = [geometryPrimitive];
                        }
                        break;
                    default:
                        console.error("Unknow property : " + prop + " in getEntitiesAtPosition filters");
                        break;
                }
            }
        }
        return returnStruct;
    }

    /**
     * Test whether the floor is valid
     *
     * @returns {*}
     */
    isFloorValid(floor: Floor) : boolean {
        return joineryManager.areJoineriesValid(floor) && wallManager.areWallsValid(floor);
    }
}

export let planManager = new PlanManager();
