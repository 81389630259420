import {Photo2World} from './photo2World/Photo2World';
import {World2Savane} from './world2Savane/World2Savane';

export class Photo2Savane {

    private _photo2world: Photo2World;
    private _world2savane: World2Savane;

    constructor(width: number, height: number, photo: number) {
        this._photo2world = new Photo2World(width, height);
        this._world2savane = new World2Savane(photo)
    }

    get Photo2World() : Photo2World {
        return this._photo2world;
    }

    BuildSavane(world, exterior, callback) {
        this._world2savane.Build(this._photo2world, world, exterior, callback);
    }

}
